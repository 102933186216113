import { NgModule } from "@angular/core";
import { TrustPipe } from "./trust.pipe";
import { CommonModule } from "@angular/common";

@NgModule({
  declarations: [TrustPipe],
  imports: [CommonModule],
  exports: [TrustPipe],
})
export class PipesModule {}
