import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';

@Injectable()
export class AuthService {

  constructor(
    public afAuth: AngularFireAuth
  ) { }

  doAnonymousLogin() {
    return this.afAuth.auth.signInAnonymously();
  }

  doLogout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('superuser_user');
    window.location.href = '/login';
    // return new Promise((resolve, reject) => {
    //   if (firebase.auth().currentUser) {
    //     this.afAuth.auth.signOut();
    //     resolve();
    //   } else {
    //     localStorage.removeItem('currentUser');
    //     reject();
    //   }
    // });
  }
}
