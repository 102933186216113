import { Component, OnInit, Renderer2, Inject, NgZone, ViewChild, HostListener, ElementRef, AfterViewInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavbarService } from '../../../app/_services/navbar.service';
import { ThemeSettingsService } from '../settings/theme-settings.service';
import { MenuSettingsService } from '../settings/menu-settings.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { AppConstants } from '../../../app/_helpers/app.constants';
import { APIService } from '../../../app/_services/api.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router, RouteConfigLoadStart, ResolveStart, RouteConfigLoadEnd, ResolveEnd } from '@angular/router';
import { VerticalComponent } from '../../_layout/header/vertical/vertical.component';
import { environment } from 'src/environments/environment';
// import $ from 'jquery';
declare var $: any;
declare var Telemo: any;
declare var stopTimer: any;
declare var getTimer: any;
declare var resetTimer: any;
declare var resetContent: any;

declare var outgoingStopTimer: any;
declare var outgoingGetTimer: any;
declare var outgoingResetTimer: any;
declare var window: any;
declare var require: any;
const colors = require('../../../assets/data/customizer/customizerColor.json');

@Component({
  selector: 'app-customizer',
  templateUrl: './customizer.component.html',
  styleUrls: ['./customizer.component.scss']
})
export class CustomizerComponent implements OnInit {
  showBackgroundWidget = false;
  contactChat: any;
  contactwhatsapp: any;
  whatsappMessages: any;
  chatMessages: any;
  contactTwitter: any;
  TwitterMessages: any;
  contactChatting: any;
  ChattingMessages: any;
  smsChatting: any;
  SMSMessages: any;
  newMessage = '';
  // box="checkdata";
  private _unsubscribeAll: Subject<any>;
  private _unsubscribeAllMenu: Subject<any>;
  public _themeCustomizerConfig: any;
  public _menuCustomizerConfig: any;
  public _themeSettingsConfig: any;
  public selectColorClass: string;
  public activebutton: any;
  phoneClose = true;
  connectData = true;
  NotconnectData = true;
  ButtonStatusCheck: any;
  public isCollapsedMenu = false;
  public isfixChecked = false;
  public isboxChecked = false;
  public isStaticLayout = false;

  public isRightSideIcons = false;
  public isBorderedNavigation = false;
  public isFlippedNavigation = false;
  public isCollapsibleNavigation = false;
  public isStaticNavigation = false;
  resumeBtn: boolean = false;
  pauseBtn: boolean = true;
  interval: any = 0;
  method_access: boolean = false;
  display_ongoing_call: any = "00:00:00";
  ringing_call: any = "00:00:00";
  display: any = "00:00:00";
  ready_call: any = "00:00:00";
  waiting_call: any = "00:00:00";
  display_set_data: any = "00:00:00";
  time: number = 0;
  public isStaticTop = false;
  telNumber = '';
  public config: PerfectScrollbarConfigInterface = { wheelPropagation: false };
  collapsed = true;
  userType: any;
  moduleLoading: boolean;
  allCustomerData: any;
  addCustomerForm: FormGroup;
  timerTime: any;
  getTime: any;
  outgoingGetTime: any;
  allAgentData: any;
  noDataFound = false;
  dataOfUser: any;
  selectedItem: any;
  showDialBtn: boolean = false;
  holdMuteBtn: boolean = true;
  conferenceRecordBtn: boolean = true;
  showDialer = false;
  showFacebookWidget = false;
  shwoWhatsappWidget = false;
  showTwitterWidget = false;
  showEmailWidget = false;
  showSmsWidget = false;
  showchatWidget = false;
  showNotification = false;
  dispositionForm: FormGroup;
  dispositions: any;
  dispositionsFound = false;
  dispositionSubmitted = false;
  selectedDispoCause = "";

  // phoneOpen = true;
  @ViewChild(VerticalComponent) verticalData: VerticalComponent;

  @ViewChild(PerfectScrollbarComponent, { static: false }) componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: true }) directiveRef?: PerfectScrollbarDirective;

  constructor(
    private fb: FormBuilder,
    private apiService: APIService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private _renderer: Renderer2,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private navbarService: NavbarService,
    private _themeSettingsService: ThemeSettingsService,
    private _menuSettingsService: MenuSettingsService,
  ) {
    this._unsubscribeAll = new Subject();
    this._unsubscribeAllMenu = new Subject();
  }

  ngOnInit() {
    $("#dailPadConTainer").hide();
    this.contactChat = [];
    this.chatMessages = [];
    this.contactwhatsapp = [];
    this.whatsappMessages = [];
    this.contactTwitter = [];
    this.TwitterMessages = [];
    this.contactChatting = [];
    this.ChattingMessages = [];
    this.smsChatting = [];
    this.SMSMessages = [];

    sessionStorage.removeItem("connecting_camp");

    // $('#customizer').draggable();
    console.log(this.telNumber)
    // Subscribe to config changes
    this._themeSettingsService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this._themeCustomizerConfig = config;
      });
    this._menuSettingsService.config
      .pipe(takeUntil(this._unsubscribeAllMenu))
      .subscribe((config) => {
        this._menuCustomizerConfig = config;
      });
    this._themeSettingsService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this._themeSettingsConfig = config;
      });
    // make the theme color select default 

    setTimeout(() => {                           //<<<---using ()=> syntax
      this.setColor('bg-gradient-x-grey-blue');
    }, 1000);
    this.dataOfUser = JSON.parse(localStorage.getItem('currentUser'));

    // dialer code start 
    // dialer show hide start from here
    $("#dailPadConTainer").show();
    $("#hideDialPadBtn").show();
    $("#makeAudioCallBtnContainer").show();

    // $(".mute_hold_call_container").hide();
    $("#mute_container").hide();
    // dialer show ends from here

    // show hide dialer
    this.userType = JSON.parse(localStorage.getItem('currentUser')).account_type;
    if (this.userType === 'Agent' || this.userType === 'Supervisor') {
      this.showDialer = true;
      this.showFacebookWidget = true;
      this.shwoWhatsappWidget = true;
      this.showTwitterWidget = true;
      this.showEmailWidget = true;
      this.showSmsWidget = true;
      this.showchatWidget = true;
      this.showNotification = true;
      this.showBackgroundWidget = true;
      // const element = document.getElementById('Notification_id').style.right="75px"
    } else {
      // document.getElementById('Notification_id').style.right="10px"
      this.showDialer = false;
      this.showBackgroundWidget = false;
      this.showFacebookWidget = false;
      this.shwoWhatsappWidget = false;
      this.showTwitterWidget = false;
      this.showEmailWidget = false;
      this.showSmsWidget = false;
      this.showchatWidget = false;
      this.showNotification = true;
    }

    if (this.userType === 'Agent' || this.userType === 'Supervisor') {
      const storedDatas = JSON.parse(localStorage.getItem('currentUser'));
      const SipUrl = environment.ProxyUrl;
      const SipPort = environment.ProxyPort;
      const domain = "wss://" + SipUrl + ":" + SipPort;
      const telemoSdk = new Telemo();
      var options = {
        'username': storedDatas['extension'],
        'password': storedDatas['extension_password'],
        'display_name': storedDatas['extension'],
        'trace_sip': 'true',
        'turnServerURL': 'turn:numb.viagenie.ca',
        'turnUsername': 'hariom@startelelogic.com',
        'turnPassword': 'q1w2e3r4',
        'subDomain': storedDatas['domain_name']
      };
      // console.log('options', options);
      telemoSdk.login(options, domain);
      // unregister sip 
      telemoSdk.unRegisterUser();
    }

    // numpad press
    setTimeout(() => {
      $('.input_container').click(function () {
        var num = $(this);
        var text = $.trim(num.find('.press_input').clone().children().remove().end().text());
        var telNumber = $('#telNumber');
        $(telNumber).val(telNumber.val() + text);
      });
    }, 2500);

    // remove outbound campaign uuid 
    sessionStorage.removeItem('get_outbound_camp_uuid');
    // dialer code ends here 
    // numpad press
    setTimeout(() => {
      $('.other_input_contain').click(function () {
        var num = $(this);
        var text = $.trim(num.find('.press_input').clone().children().remove().end().text());
        var telNumberAfter = $('#telNumberAfter');
        $(telNumberAfter).val(telNumberAfter.val() + text);
      });
    }, 2500);


    setTimeout(() => {
      const element = document.getElementById('customizer');
      if (element && element.classList.contains('open')) {
      } else {
        this._renderer.addClass(element, 'open');
      }
    }, 1000);
  }

  // ngAfterViewInit() {
  //   let dragDrop = {
  //     initialMouseX: undefined,
  //     initialMouseY: undefined,
  //     startX: undefined,
  //     startY: undefined,
  //     dXKeys: undefined,
  //     dYKeys: undefined,
  //     draggedObject: undefined,
  //     dragContainerWidth: undefined,
  //     dragContainerHeight: undefined,
  //     initElement: (element, width, height) => {
  //       if (typeof element == 'string')
  //         element = document.getElementById(element);
  //       element.onmousedown = (event) => dragDrop.startDragMouse(event, element);
  //       dragDrop.dragContainerWidth = width;
  //       dragDrop.dragContainerHeight = height;
  //     },
  //     startDragMouse: (e, element) => {
  //       dragDrop.startDrag(element);
  //       var evt = e || window.event;
  //       dragDrop.initialMouseX = evt.clientX;
  //       dragDrop.initialMouseY = evt.clientY;
  //       dragDrop.addEventSimple(document, 'mousemove', dragDrop.dragMouse);
  //       dragDrop.addEventSimple(document, 'mouseup', dragDrop.releaseElement);
  //       //  return false;
  //     },
  //     startDrag: (obj) => {
  //       if (dragDrop.draggedObject)
  //         dragDrop.releaseElement();
  //       dragDrop.startX = obj.offsetLeft;
  //       dragDrop.startY = obj.offsetTop;
  //       dragDrop.draggedObject = obj;
  //       obj.className += ' dragged';
  //     },
  //     dragMouse: (e) => {
  //       if (!this.showDialer) return;
  //       var evt = e || window.event;
  //       var dX = evt.clientX - dragDrop.initialMouseX;
  //       var dY = evt.clientY - dragDrop.initialMouseY;
  //       dragDrop.setPosition(dX, dY);
  //       return false;
  //     },
  //     setPosition: (dx, dy) => {
  //       dragDrop.draggedObject.style.left = (Math.min(window.innerWidth - dragDrop.dragContainerWidth, Math.max(0, (dragDrop.startX + dx))) / window.innerWidth) * 100 + "%";
  //       dragDrop.draggedObject.style.top = (Math.min(window.innerHeight - dragDrop.dragContainerHeight, Math.max(0, (dragDrop.startY + dy))) / window.innerHeight) * 100 + "%";
  //     },
  //     releaseElement: function () {
  //       dragDrop.removeEventSimple(document, 'mousemove', dragDrop.dragMouse);
  //       dragDrop.removeEventSimple(document, 'mouseup', dragDrop.releaseElement);
  //       dragDrop.draggedObject.className = dragDrop.draggedObject.className.replace(/dragged/, '');
  //       dragDrop.draggedObject = null;
  //     },
  //     addEventSimple: (obj, evt, fn) => {
  //       if (obj.addEventListener)
  //         obj.addEventListener(evt, fn, false);
  //       else if (obj.attachEvent)
  //         obj.attachEvent('on' + evt, fn);
  //     },
  //     removeEventSimple: (obj, evt, fn) => {
  //       if (obj.removeEventListener)
  //         obj.removeEventListener(evt, fn, false);
  //       else if (obj.detachEvent)
  //         obj.detachEvent('on' + evt, fn);
  //     }
  //   };
  //   dragDrop.initElement(document.getElementById('customizer'), 250, 450);
  // }
  onEnter(value: string) {
    this.newMessage = value;
    console.log(this.newMessage)
  }
  sendWhatsappMessage() {
    this.contactChat = {
      message: this.newMessage,
      time: "6:30am"
    }
    this.chatMessages.push(this.contactChat)
    this.newMessage = "";
  }
  sendMessage() {
    this.contactwhatsapp = {
      message: this.newMessage,
      time: "6:30am"
    }
    this.whatsappMessages.push(this.contactwhatsapp)
    this.newMessage = "";
  }
  sendTwitterMessage() {
    this.contactTwitter = {
      message: this.newMessage,
      time: "6:30am"
    }
    this.TwitterMessages.push(this.contactTwitter)
    this.newMessage = "";
  }
  sendChattingMessage() {
    this.contactChatting = {
      message: this.newMessage,
      time: "6:30am"
    }
    this.ChattingMessages.push(this.contactChatting)
    this.newMessage = "";
  }
  sendSMSMessage() {
    this.smsChatting = {
      message: this.newMessage,
      time: "6:30am"
    }
    this.SMSMessages.push(this.smsChatting)
    this.newMessage = "";
  }
  availableCheck(data) {
    this.resetTimer();
    if (data === '') {
      this.display = "00:00:00"
    } else {
      this.resetTimer();
      this.startTimerrr();
    }

    // if(strUser==='available'){
    //   getOption.style.color="green"
    // }else{
    //   getOption.style.color="red"
    // }
    // this.myStopFunction();
  }

  // start time counter 
  startTimerrr() {
    this.interval = setInterval(() => {
      if (this.time === 0) {
        this.time++;
      } else {
        this.time++;
      }
      this.display = this.transform(this.time)
    }, 1000);
  }
  transform(value: number): string {
    var sec_num = value;
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    return ('00' + hours).slice(-2) + ':' + ('00' + minutes).slice(-2) + ':' + ('00' + seconds).slice(-2);
  }

  resetTimer() {
    clearInterval(this.interval);
    this.interval = setTimeout(() => {
      this.time = 0;
    }, 0);
  }
  pauseClick() {
    this.resumeBtn = true;
    this.pauseBtn = false;
  }
  resumeClick() {
    this.pauseBtn = true;
    this.resumeBtn = false;
  }
  // changeDataCheck(){
  //   alert("hhgbfjefujewh")
  // }
  toggleCustomizer(event) {

    const element = document.getElementById('customizer');
    if (element && element.classList.contains('open')) {
      // this._renderer.removeClass(element, 'open');
    } else {
      // this.phoneOpen = false;
      this._renderer.addClass(element, 'open');
    }
  }
  toggleCloseCustomizer() {
    document.getElementById('agent_is_registered').style.visibility = "visible";

    // console.log(this.ButtonStatusCheck)
    const element = document.getElementById('customizer');
    element.style.left = "";
    element.style.top = "";

    if (element && element.classList.contains('open')) {
      this._renderer.removeClass(element, 'open');
      element.style.visibility = "none";
      // alert("If")

    } else {
      // this._renderer.removeClass(element, 'open');
      // this.phoneOpen=true;
      // alert("else")

    }
  }

  // toogle facebook widget section start from here 
  toggleFacebookWidget() {
    const element = document.querySelector('#facebookWidgetForm');
    const style = getComputedStyle(element);
    if (style.display === 'block') {
      document.getElementById("facebookWidgetForm").style.display = "none";
    } else {
      document.getElementById("facebookWidgetForm").style.display = "block";
    }
  }
  // toogle facebook widget section ends from here 

  // toogle whatsapp widget section start from here 
  toggleWhatsappWidget() {
    const element = document.querySelector('#whatsappWidgetForm');
    const style = getComputedStyle(element);
    if (style.display === 'block') {
      document.getElementById("whatsappWidgetForm").style.display = "none";
    } else {
      document.getElementById("whatsappWidgetForm").style.display = "block";
    }
  }
  // toogle watsapp widget section ends from here 

  // toogle twitter widget section start from here 
  toggleTwitterWidget() {
    const element = document.querySelector('#twitterWidgetForm');
    const style = getComputedStyle(element);
    if (style.display === 'block') {
      document.getElementById("twitterWidgetForm").style.display = "none";
    } else {
      document.getElementById("twitterWidgetForm").style.display = "block";
    }
  }
  // toogle twitter widget section ends from here 

  // toogle sms widget section start from here 
  toggleSmsidget() {
    const element = document.querySelector('#smsWidgetForm');
    const style = getComputedStyle(element);
    if (style.display === 'block') {
      document.getElementById("smsWidgetForm").style.display = "none";
    } else {
      document.getElementById("smsWidgetForm").style.display = "block";
    }
  }
  // toogle sms widget section ends from here 

  // toogle chat widget section start from here 
  toggleChatWidget() {
    const element = document.querySelector('#chatWidgetForm');
    const style = getComputedStyle(element);
    if (style.display === 'block') {
      document.getElementById("chatWidgetForm").style.display = "none";
    } else {
      document.getElementById("chatWidgetForm").style.display = "block";
    }
  }
  // toggle chat widget section ends from here

  // toogle twitter widget section start from here 
  // toggleNotification() {
  //   const element = document.querySelector('#notifyWidgetForm');
  //   const style = getComputedStyle(element);
  //   if (style.display === 'block') {
  //     document.getElementById("notifyWidgetForm").style.display = "none";
  //   } else {
  //     document.getElementById("notifyWidgetForm").style.display = "block";
  //   }
  // }
  // toogle twitter widget section ends from here 

  setLayout(layout, e) {
    const footer = document.getElementById('footer');
    const headerNavbar = document.getElementsByClassName('header-navbar');
    const mainMenu = document.getElementsByClassName('main-menu');

    if (layout === 'static' && e.currentTarget.checked === true) {
      this.staticLayout(e);
    } else if (layout === 'static' && e.currentTarget.checked === false) {
      this._themeSettingsConfig.layout.pattern = '';
      this._renderer.removeClass(headerNavbar.item(0), 'navbar-static-top');
      this._renderer.removeClass(footer, 'footer-static');
      this._renderer.addClass(headerNavbar.item(0), 'fixed-top');
      this._renderer.addClass(document.body, 'fixed-navbar');
      this._renderer.addClass(mainMenu.item(0), 'menu-fixed');
      this.isStaticLayout = false;
    }
  }

  toggleFixMenu(e) {

    const toggleIcon = document.getElementsByClassName('toggle-icon');

    if (this.document.body.classList.contains('menu-expanded') && e.currentTarget.checked === true) {
      this._themeCustomizerConfig.navbar = 'collapse';
      // show the left aside menu
      this.navbarService.setFixedMenu(false);
      this.document.body.classList.remove('menu-expanded');
      this.document.body.classList.add('menu-collapsed');
      // Change switch icon
      this._renderer.removeClass(toggleIcon.item(0), 'ft-toggle-right');
      this._renderer.addClass(toggleIcon.item(0), 'ft-toggle-left');
      this.isCollapsedMenu = true;
    } else {
      this._themeCustomizerConfig.navbar = 'expand';
      this.navbarService.setFixedMenu(true);
      this.document.body.classList.remove('menu-collapsed');
      this.document.body.classList.add('menu-expanded');
      // Change switch icon
      this._renderer.removeClass(toggleIcon.item(0), 'ft-toggle-left');
      this._renderer.addClass(toggleIcon.item(0), 'ft-toggle-right');
      this.isCollapsedMenu = false;
    }
    setTimeout(() => { AppConstants.fireRefreshEventOnWindow(); }, 300);
    this.resetOpenMenu();
  }

  resetOpenMenu() {
    for (let i = 0; i < this._menuCustomizerConfig.vertical_menu.items.length; i++) {
      const menu = this._menuCustomizerConfig.vertical_menu.items[i];
      if (!menu.submenu) {
        menu['isOpen'] = false;
        menu['isActive'] = false;
        menu['hover'] = false;
      } else if (menu.submenu) {
        for (let j = 0; j < menu.submenu.items.length; j++) {
          menu['isOpen'] = false;
          menu['isActive'] = false;
          menu['hover'] = false;
          menu.submenu.items[j]['isOpen'] = false;
        }
      }
    }
  }

  changeNavbarFontColor($event: NgbTabChangeEvent) {
    const headerElement = document.getElementsByClassName('header-navbar');
    const navbarHeaderElement = document.getElementsByClassName('navbar-header');
    if ($event.nextId === 'semi-dark') {
      this._renderer.removeClass(headerElement.item(0), 'navbar-dark');
      this._renderer.removeClass(headerElement.item(0), 'navbar-semi-light');
      this._renderer.removeClass(headerElement.item(0), 'navbar-light');
      this._renderer.addClass(headerElement.item(0), 'navbar-semi-dark');
      this._themeCustomizerConfig.colorTheme = 'semi-dark';
    } else if ($event.nextId === 'semi-light') {
      this._renderer.removeClass(headerElement.item(0), 'navbar-dark');
      this._renderer.removeClass(headerElement.item(0), 'navbar-semi-dark');
      this._renderer.removeClass(headerElement.item(0), 'navbar-light');
      this._renderer.addClass(headerElement.item(0), 'navbar-semi-light');
      this._themeCustomizerConfig.colorTheme = 'semi-light';
    } else if ($event.nextId === 'dark') {
      this._renderer.removeClass(headerElement.item(0), 'navbar-semi-light');
      this._renderer.removeClass(headerElement.item(0), 'navbar-semi-dark');
      this._renderer.removeClass(headerElement.item(0), 'navbar-light');
      this._renderer.addClass(headerElement.item(0), 'navbar-dark');
      this._themeCustomizerConfig.colorTheme = 'dark';
    } else if ($event.nextId === 'light') {
      this._renderer.removeClass(headerElement.item(0), 'navbar-dark');
      this._renderer.removeClass(headerElement.item(0), 'navbar-semi-dark');
      this._renderer.removeClass(headerElement.item(0), 'navbar-semi-light');
      this._renderer.addClass(headerElement.item(0), 'navbar-light');
      this._themeCustomizerConfig.colorTheme = 'light';
    }
    this._themeSettingsService.config = {
      color: ''
    };
  }

  setMenuColor(colorClass, event) {
    const darkMenuButton = document.getElementById('dark-menu');
    const lightMenuButton = document.getElementById('light-menu');

    if (event.currentTarget.className === 'btn btn-outline-info _dark') {
      this._renderer.removeClass(lightMenuButton, 'active');
      this._renderer.addClass(darkMenuButton, 'active');
    } else if (event.currentTarget.className === 'btn btn-outline-info _light') {
      this._renderer.removeClass(darkMenuButton, 'active');
      this._renderer.addClass(lightMenuButton, 'active');
    }

    this._themeSettingsService.config = {
      menuColor: colorClass, // light-menu, dark-menu
    };
  }

  setNavigation(navigationClass, event) {
    const navigationElement = document.getElementById('main-menu');
    const element = document.getElementById('customizer');
    if (event.target.checked === true && navigationClass !== 'menu-collapsible') {
      this._themeSettingsService.config = {
        navigation: navigationClass
      };
      if (navigationClass === 'menu-flipped') {
        this._renderer.removeClass(element, 'open');
      } else if (navigationClass === 'menu-static') {
        this._renderer.removeClass(navigationElement, 'menu-fixed');
      }
    } else if (event.target.checked === false && navigationClass !== 'menu-collapsible') {
      this._themeSettingsConfig.navigation = AppConstants.NAVIGATION_TYPE_ACCORDATION;
      this._renderer.removeClass(navigationElement, navigationClass);
      this._renderer.removeClass(document.body, navigationClass);

      if (navigationClass === 'menu-static') {
        this._renderer.addClass(navigationElement, 'menu-fixed');
      }
    }

    if (event.target.checked === true && navigationClass === 'menu-collapsible') {
      this._themeSettingsConfig.navigation = AppConstants.NAVIGATION_TYPE_ACCORDATION;
      this._renderer.removeClass(navigationElement, navigationClass);
    } else if (event.target.checked === false && navigationClass === 'menu-collapsible') {
      this._themeSettingsService.config = {
        navigation: navigationClass
      };
    }

    if (navigationClass === 'menu-icon-right' && event.currentTarget.checked === true) {
      this.isRightSideIcons = true;
    } else if (navigationClass === 'menu-icon-right' && event.currentTarget.checked === false) {
      this.isRightSideIcons = false;
    }
    if (navigationClass === 'menu-bordered' && event.currentTarget.checked === true) {
      this.isBorderedNavigation = true;
    } else if (navigationClass === 'menu-bordered' && event.currentTarget.checked === false) {
      this.isBorderedNavigation = false;
    }

    if (navigationClass === 'menu-flipped' && event.currentTarget.checked === true) {
      this.isFlippedNavigation = true;
    } else if (navigationClass === 'menu-flipped' && event.currentTarget.checked === false) {
      this.isFlippedNavigation = false;
    }

    if (navigationClass === 'menu-collapsible' && event.currentTarget.checked === true) {
      this.isCollapsibleNavigation = true;
    } else if (navigationClass === 'menu-collapsible' && event.currentTarget.checked === false) {
      this.isCollapsibleNavigation = false;
    }

    if (navigationClass === 'menu-static' && event.currentTarget.checked === true) {
      this.isStaticNavigation = true;
    } else if (navigationClass === 'menu-static' && event.currentTarget.checked === false) {
      this.isStaticNavigation = false;
    }
  }

  setNavbar(event) {
    const navbarElement = document.getElementsByClassName('header-navbar');
    const navigationElement = document.getElementById('main-menu');
    if (event.target.checked === true) {
      this._renderer.removeClass(document.body, 'fixed-navbar');
      this._renderer.removeClass(navbarElement.item(0), 'fixed-top');
      this._renderer.removeClass(navigationElement, 'menu-fixed');
      this._renderer.addClass(navbarElement.item(0), 'navbar-static-top');
      this._renderer.addClass(navigationElement, 'menu-static');
      this.isStaticTop = true;
    } else if (event.target.checked === false) {
      this._renderer.removeClass(navbarElement.item(0), 'navbar-static-top');
      this._renderer.removeClass(navigationElement, 'menu-static');
      this._renderer.addClass(document.body, 'fixed-navbar');
      this._renderer.addClass(navbarElement.item(0), 'fixed-top');
      this._renderer.addClass(navigationElement, 'menu-fixed');
      this.isStaticTop = false;
    }
  }

  setColor(colorClass) {
    for (let i = 0; i <= colors.colorArray.length; i++) {
      if (colorClass === colors.colorArray[i].cssClass) {
        this.selectColorClass = colorClass;
        break;
      }
    }
    this._themeSettingsService.config = {
      color: this.selectColorClass
    };
  }

  toggleLayout(layout) {
    const footer = document.getElementById('footer');
    const headerNavbar = document.getElementsByClassName('header-navbar');
    if (layout === 'boxed' && this.isboxChecked === false) {
      this.boxedLayout();
      setTimeout(() => { AppConstants.fireRefreshEventOnWindow(); }, 300);
    } else if (layout === 'boxed' && this.isboxChecked === true) {
      this._renderer.removeClass(headerNavbar.item(0), 'container');
      this._renderer.removeClass(headerNavbar.item(0), 'boxed-layout');
      this._renderer.removeClass(document.body, 'boxed-layout');
      this._renderer.removeClass(document.body, 'container');
      setTimeout(() => { AppConstants.fireRefreshEventOnWindow(); }, 300);
      this._themeSettingsConfig.layout.pattern = '';
    } else if (layout === 'fixed' && this.isfixChecked === false) {
      this.fixedLayout();
      setTimeout(() => { AppConstants.fireRefreshEventOnWindow(); }, 300);
    } else if (layout === 'fixed' && this.isfixChecked === true) {
      this._renderer.removeClass(footer, 'fixed-bottom');
      this._themeSettingsConfig.layout.pattern = '';
      setTimeout(() => { AppConstants.fireRefreshEventOnWindow(); }, 300);
    }
  }

  fixedLayout() {
    const footer = document.getElementById('footer');
    const headerNavbar = document.getElementsByClassName('header-navbar');
    this._renderer.removeClass(headerNavbar.item(0), 'container');
    this._renderer.removeClass(headerNavbar.item(0), 'boxed-layout');
    this._renderer.removeClass(document.body, 'boxed-layout');
    this._renderer.removeClass(document.body, 'container');
    this._renderer.addClass(footer, 'fixed-bottom');
    if (this.isStaticLayout === true) {
      this._renderer.addClass(headerNavbar.item(0), 'fixed-top');
      this._renderer.addClass(document.body, 'fixed-navbar');
      this._renderer.removeClass(footer, 'fixed-bottom');
    }
    this.isfixChecked = true;
    this.isboxChecked = false;
    this._themeSettingsConfig.layout.pattern = 'fixed';
  }

  boxedLayout() {
    const footer = document.getElementById('footer');
    const headerNavbar = document.getElementsByClassName('header-navbar');
    this._renderer.removeClass(footer, 'fixed-bottom');
    this._renderer.addClass(headerNavbar.item(0), 'container');
    this._renderer.addClass(headerNavbar.item(0), 'boxed-layout');
    this._renderer.addClass(headerNavbar.item(0), 'fixed-top');
    this._renderer.addClass(document.body, 'boxed-layout');
    this._renderer.addClass(document.body, 'container');
    if (this.isStaticLayout === true) {
      this._renderer.removeClass(headerNavbar.item(0), 'fixed-top');
      this._renderer.removeClass(document.body, 'fixed-navbar');
    }
    this.isboxChecked = true;
    this.isfixChecked = false;
    this._themeSettingsConfig.layout.pattern = 'boxed';
  }

  staticLayout(e) {
    const footer = document.getElementById('footer');
    const headerNavbar = document.getElementsByClassName('header-navbar');
    const mainMenu = document.getElementsByClassName('main-menu');
    this._renderer.removeClass(document.body, 'fixed-navbar');
    this._renderer.removeClass(headerNavbar.item(0), 'fixed-top');
    this._renderer.removeClass(mainMenu.item(0), 'menu-fixed');
    this._renderer.addClass(headerNavbar.item(0), 'navbar-static-top');
    this._renderer.addClass(footer, 'footer-static');
    this._renderer.removeClass(footer, 'fixed-bottom');
    this.isStaticLayout = true;
    this._themeSettingsConfig.layout.pattern = 'static';
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.document.body.classList.contains('menu-expanded')) {
      this.collapsed = false;
    } else {
      this.collapsed = true;
    }
  }

  // call related code start here 
  isNumber(evt: KeyboardEvent) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  isNumberCall(evt: KeyboardEvent) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  makeAudioCall() {
    this.loadDisposition();
    $('#closeDialpad').hide();
    const dataStatus = sessionStorage.getItem("media_status");
    console.log(dataStatus)
    if (dataStatus != null) {
      console.log("check")
    }
    else {
      console.log("non_check")
    }
    var getOption = <HTMLInputElement>document.getElementById('avialability_check')
    var getStatusOption = <HTMLInputElement>document.getElementById('agentCallStatus')
    console.log(getStatusOption.innerText)
    // var daatest = <HTMLInputElement>document.getElementById('dropdownTestStatus')
    // console.log(daatest.onclick)
    const telemoSdk = new Telemo();
    var getcallInput = $("#telNumber").val();
    if (getcallInput === undefined || getcallInput == null || getcallInput.length <= 0) {
      this.toastr.error('Please provide a Number.', 'Error!');
    } else if (getOption.innerText != "Available") {
      this.toastr.error('You are on a break', 'Error!');
    }
    else {
      // const campUUID = '';
      sessionStorage.removeItem('media_status')
      const campUUID = sessionStorage.getItem('get_outbound_camp_uuid');
      const outBoundSipTrunkUUID = sessionStorage.getItem('get_outbound_siptrunk_siptrunk_uuid');
      const agentUUID = this.dataOfUser.agent_domain_uuid;
      const queueUUID = sessionStorage.getItem('outbound_campaign_queue_uuid');
      if (sessionStorage.getItem('get_outbound_camp_uuid') === null || sessionStorage.getItem('get_outbound_camp_uuid') === 'undefined') {
        this.toastr.error('No outbound campaign selected', 'Error!');
      } else if (sessionStorage.getItem('get_outbound_camp_uuid') !== null || sessionStorage.getItem('get_outbound_camp_uuid') !== 'undefined') {
        console.log('invite_detail', $("#telNumber").val(), campUUID, outBoundSipTrunkUUID, '', agentUUID);
        telemoSdk.audioCall($("#telNumber").val(), campUUID, outBoundSipTrunkUUID, '', agentUUID);
        sessionStorage.setItem('camp_uuid_for_call_tab', sessionStorage.getItem('get_outbound_camp_uuid'));
        sessionStorage.setItem("camp_uuid_for_outcome", sessionStorage.getItem('get_outbound_camp_uuid'));
        sessionStorage.setItem('outcome_caller_number', $("#telNumber").val());
        sessionStorage.setItem("caller_number", $("#telNumber").val());
        $("#terminateCall").show();
        $("#makeAudioCall").hide();
        // new controls placed below
        $("#makeAudioCallBtnContainer").hide();
        $("#dailPadConTainer").hide();
        $("#outgoingResetTimer").hide();
        $("#resetTime").hide();
        $("#outgoingResetTimer").hide();
        $("#dailPadConTainer").hide();
        // $("#terminateCall").css({"mar": "yellow", "font-size": "200%"});
      }
    }
  }
  // getAvatar() {
  //   return (this.allAgentData.agent_name).charAt(0);
  // }
  acceptCall() {
    // sessionStorage.removeItem("caller_number");
    const telemoSdk = new Telemo();
    telemoSdk.acceptCall();
    $("#makeAudioCall").hide();
    $("#resetTime").css("display", "block");
    //this.startTimer()
  }

  holdCall() {
    const telemoSdk = new Telemo();
    telemoSdk.holdLocal();
    $("#holdCall").hide();
    $("#unholdCall").show();
  }

  callUnHold() {
    const telemoSdk = new Telemo();
    telemoSdk.holdLocal();
    $("#holdCall").show();
    $("#unholdCall").hide();
  }
  showRecieveDialpad() {
    $("#dailPadConTainerAfterRecieved, #conferenceCall , .holdMuteBtn , .unhold_container , .unmute_container , .recording_container , #conferenceRecordBtn , .transfer_container , .mute_hold_call_containe , .hold_container , .mute_container , #mute_container , #parkCall , #holdCall , #unholdCall").toggle();
  }
  terminateCall(model) {

    $('#receive_dialpad_container').hide();
    this.document.getElementById('callieNumber').classList.remove("callieNumberdata");
    this.modalService.open(model, { scrollable: false, centered: true, windowClass: 'animated fadeInDown', size: 'lg' });
    $('#cnt-list').hide();
    sessionStorage.removeItem("caller_number");
    sessionStorage.removeItem('camp_uuid_for_call_tab');
    $("#makeAudioCallBtnContainer").show();
    $("#dailPadConTainer").show();
    $("#holdCall").hide();
    $("#unholdCall").hide();
    $("#muteCall").hide();
    $("#unmuteCall").hide();
    $("#parkCall").hide();
    $("#volControl").hide();
    $("#conferenceCall").hide();
    $("#recordCall").hide();
    $("#transferCall").hide();
    $("#dialpadCall").hide();
    $("#outgoingResetTimer").hide();
    $("#resetTime").hide();
    $(".mute_hold_call_container").hide();
    $(".mute_hold_call_container").removeClass("displayFlex");
    $("#mute_container").hide();
    const telemoSdk = new Telemo();
    $("#terminateCall").hide();
    telemoSdk.terminateCall();
    $("#wrapper").show();
  }

  muteCall() {
    const telemoSdk = new Telemo();
    telemoSdk.muteCall();
    $("#muteCall").hide();
    $("#unmuteCall").show();
  }

  unmuteCall() {
    const telemoSdk = new Telemo();
    telemoSdk.unmuteCall();
    $("#unmuteCall").hide();
    $("#muteCall").show();
  }

  startTimer() {
    $("#resetTime").css({ "display": "block" });
    var getTime = document.getElementsByTagName('h1')[0],
      seconds = 0, minutes = 0, hours = 0;
    function add() {
      seconds++;
      if (seconds >= 60) {
        seconds = 0;
        minutes++;
        if (minutes >= 60) {
          minutes = 0;
          hours++;
        }
      }
      getTime.textContent = (hours ? (hours > 9 ? hours : "0" + hours) : "00") + ":" + (minutes ? (minutes > 9 ? minutes : "0" + minutes) : "00") + ":" + (seconds > 9 ? seconds : "0" + seconds);
      window.getTime = getTime;
      timer();
    }
    function timer() {
      window.stopTimer = setTimeout(add, 1000);
    }
    timer();
  }

  // transfer senction start from here 
  openTransferToAgent() {
    $('#cnt-list').toggle(200);
    this.showAvailableAgent();
    console.log(this.allAgentData.agent_name.charAt(0))

  }
  showAvailableAgent() {
    const requestDta = {
      'domain_uuid': JSON.parse(localStorage.getItem('currentUser')).domain_uuid
    };
    // this.apiService.showAgentList(requestDta).subscribe((response) => {
    //   if (response['Status'] == 0) {
    //     this.noDataFound = true;
    //   } else {
    //     this.noDataFound = false;
    //     this.allAgentData = response['AgentList'];
    //   }
    // });
  }
  selectChangeHandler(sel: any) {
    this.selectedItem = sel.target.value;
  }
  callTransfer(agent_extension) {
    const telemoSdk = new Telemo();
    telemoSdk.transferCall(agent_extension);
    $('#cnt-list').toggle(200);
  }
  // call transfer section ends here 

  // remove number from input 
  backspace() {
    var input, num;
    input = document.getElementById('telNumber');
    num = input.value;
    input.value = num.slice(0, num.length - 1);
    return false;
  }

  backspaceAfteRecieved() {
    var input, num;
    input = document.getElementById('telNumberAfter');
    num = input.value;
    input.value = num.slice(0, num.length - 1);
    return false;
  }

  loadDisposition() {
    this.dataOfUser.agent_name;
    this.dispositionForm = this.fb.group({
      dispose_message: [''],
      call_quality:[''],
    });
    if (sessionStorage.getItem('get_outbound_camp_uuid') !== null || sessionStorage.getItem('get_outbound_camp_uuid') !== 'undefined') {
      // this.apiService.getCampaignDisposition(sessionStorage.getItem('get_outbound_camp_uuid')).subscribe((response) => {
      //   if (response['Status'] === String(1)) {
      //     this.dispositions = JSON.parse(response['camp_disposition_datas']);
      //     this.dispositionsFound = true;
      //   }else{
     
      //   }
      // });
    }
  }

  get disposeFormalert() { return this.dispositionForm.controls; }
  submitDisposition() {
 
    $("#ready_call_timer").css({ "display": "block" });
    $("#readyTimerHeadFull").css({ "display": "block" });
    $("#agentCallStatus").text('Ready')

    this.dispositionSubmitted = true;
    if (this.dispositionForm.invalid) {
      return;
    }
    const call_disposition_data = {
      domain_uuid:this.dataOfUser.domain_uuid,
      disposition_cause:this.selectedDispoCause,
      description:this.dispositionForm.value['dispose_message'],
      call_quality:String(this.dispositionForm.value['call_quality']),
      agent_uuid: this.dataOfUser.agent_domain_uuid,
      mobile_no:sessionStorage.getItem('outcome_caller_number'),
      camp_uuid: sessionStorage.getItem('get_outbound_camp_uuid'),
      xml_cdr_uuid:sessionStorage.getItem("current_call_uuid"),
    }
    console.log(call_disposition_data)
    
    // this.apiService.storeDispositionOutcome(call_disposition_data).subscribe((response) => {
    //   if (response['Status'] === String(1)){
    //     this.toastr.success(response['Msg'], 'Success!');
    //   }else{
    //     this.toastr.error(response['Msg'], 'Error!');

    //   }
    // })
    // this.toastr.success('Call Disposition Submitted Successfully', 'Success!');
  }
}
