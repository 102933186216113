import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
} from "@angular/platform-browser";
import { routing } from "./app.routing";
import { NgModule } from "@angular/core";
import { LoginComponent } from "./login";
import { ToastrModule } from "ngx-toastr";
import { ChartsModule } from "ng2-charts";
import { DatePipe } from "@angular/common";
import { BlockUIModule } from "ng-block-ui";
import { ChartistModule } from "ng-chartist";
import { FormsModule } from "@angular/forms";
import { LightboxModule } from 'ngx-lightbox';
import { NgxEchartsModule } from "ngx-echarts";
import { RouterModule } from "@angular/router";
import { NgxSpinnerModule } from "ngx-spinner";
import { AppComponent } from "./app.component";
import { AuthGuard } from "./_guards/auth.guard";
import { AngularFireModule } from "angularfire2";
import { PipesModule } from './_pipes/pipes.module';
import { PickerModule } from "@ctrl/ngx-emoji-mart";
import { ReactiveFormsModule } from "@angular/forms";
import { DataApiService } from "./_services/data.api";
import { AuthService } from "./_services/auth.service";
import { HttpClientModule } from "@angular/common/http";
import { AlertService } from "./_services/alert.service";
import { NgxCaptchaModule } from "@binssoft/ngx-captcha";
import { environment } from "../environments/environment";
import { AngularFireAuthModule } from "angularfire2/auth";
import { NavbarService } from "./_services/navbar.service";
import { DeviceDetectorModule } from "ngx-device-detector";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { AlertComponent } from "./_directives/alert.component";
import { AngularFirestoreModule } from "angularfire2/firestore";
import { AngularDualListBoxModule } from "angular-dual-listbox";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { SettingsModule } from "./_layout/settings/settings.module";
import { FooterComponent } from "./_layout/footer/footer.component";
import { HeaderComponent } from "./_layout/header/header.component";
import { ChangelogComponent } from "./changelog/changelog.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { MenuSettingsConfig } from "./_layout/settings/menu-settings.config";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ThemeSettingsConfig } from "./_layout/settings/theme-settings.config";
import { CustomizerComponent } from "./_layout/customizer/customizer.component";
import { FullLayoutComponent } from "./_layout/full-layout/full-layout.component";
import { BlockTemplateComponent } from "./_layout/blockui/block-template.component";
import { PublicLayoutComponent } from "./_layout/public-layout/public-layout.component";
import { NgbModule, NgbCarouselConfig, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { PrivateLayoutComponent } from "./_layout/private-layout/private-layout.component";
import { VerticalnavComponent } from "./_layout/navigation/verticalnav/verticalnav.component";
import { HorizontalnavComponent } from "./_layout/navigation/horizontalnav/horizontalnav.component";
import { NavigationComponent as AppNavigationComponent } from "./_layout/navigation/navigation.component";
import { VerticalComponent as HeaderVerticalComponent } from "./_layout/header/vertical/vertical.component";
import { FullLayoutNavbarComponent } from "./_layout/header/full-layout-navbar/full-layout-navbar.component";
import { HorizontalComponent as HeaderHorizontalComponent } from "./_layout/header/horizontal/horizontal.component";
import { HorizontalCustomizerComponent } from "./_layout/customizer/horizontal-customizer/horizontal-customizer.component";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  imports: [
    routing,
    NgbModule,
    PipesModule,
    FormsModule,
    ChartsModule,
    PickerModule,
    BrowserModule,
    LightboxModule,
    ChartistModule,
    HttpClientModule,
    NgxEchartsModule,
    NgxCaptchaModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    BrowserAnimationsModule,
    AngularDualListBoxModule,
    // Settings modules
    PerfectScrollbarModule,
    LoadingBarRouterModule,
    ToastrModule.forRoot({
      positionClass: "toast-bottom-left",
    }),
    BlockUIModule.forRoot({
      template: BlockTemplateComponent,
    }),
    DeviceDetectorModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    SettingsModule.forRoot(ThemeSettingsConfig, MenuSettingsConfig),
  ],
  declarations: [
    AppComponent,
    AlertComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    ChangelogComponent,
    FullLayoutComponent,
    VerticalnavComponent,
    CustomizerComponent,
    PublicLayoutComponent,
    PrivateLayoutComponent,
    BlockTemplateComponent,
    HorizontalnavComponent,
    AppNavigationComponent,
    HeaderVerticalComponent,
    FullLayoutNavbarComponent,
    HeaderHorizontalComponent,
    HorizontalCustomizerComponent,
  ],
  providers: [
    DatePipe,
    AuthGuard,
    AlertService,
    NavbarService,
    NgbModalConfig,
    DataApiService,
    NgbCarouselConfig,
    AuthService,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerGestureConfig,
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [BlockTemplateComponent],
  exports: [RouterModule, CustomizerComponent],
})
export class AppModule {}
