import { Injectable } from "@angular/core";
import { AbstractControl } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class FormValidationService {
  regex: Object = {
    name: new RegExp("^[a-zA-Z\\.\\s]+$"),
  };

  regex_error_message: Object = {
    [this.regex["name"]]: "Only alphabets, spaces and period(.) are allowed",
  };

  constructor() {}

  /**
   * get the error description of form control
   * @param { AbstractControl } control - get error description of specific control
   * @returns { string } - Error description if present
   */
  form_control_error_description(control: AbstractControl): string {
    if (!(control.touched && control.errors)) return "";
    for (let error in control.errors) {
      switch (error) {
        case "required":
          return "Value is required";
          break;

        case "minlength":
          return `Text minimum length should be ${control.errors[error]["requiredLength"]}`;
          break;

        case "maxlength":
          return `Text maximum length should be ${control.errors[error]["requiredLength"]}`;
          break;

        case "pattern":
          return `Invalid pattern ${
            this.regex_error_message[control.errors[error]["requiredPattern"]]
          }`;
          break;

        case "confirmedValidator":
          return " Password and Confirm Password must match.";
          break;

        default:
          return "";
          break;
      }
    }
  }
}