import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class APIService {

    API_URL = environment.API_URL;
    AGENT_QUEUE_URL = environment.AGENT_QUEUE_URL;
    SOCIAL_MEDIA_URL = environment.SOCIAL_MEDIA_URL;

    constructor(
        private httpClient: HttpClient
    ) { }

    login(data) {
        return this.httpClient.post(`${this.API_URL}account/login/`, data);
    }
    sendMessage(data) {
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}send-webchat-message`, data);
    }
    joinUser(){
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}join_user`);
    }
    sendMediaFile(data){
        return this.httpClient.post(`${this.AGENT_QUEUE_URL}send-webchat-media-message`, data);
    }
    JoinUserId(Intergration,Joinuserid,first_name){
        return this.httpClient.get(`${this.AGENT_QUEUE_URL}webchat-welcome-message/${Intergration}/${Joinuserid}/${first_name}`)
    }
}