export interface MenuItem {
  title: string;
  icon: string;
  page: string;
  isExternalLink?: boolean;
  issupportExternalLink?: boolean;
  badge: { type: string, value: string };
  submenu: {
    items: Partial<MenuItem>[];
  };
  section: string;
}

export interface MenuConfig {
  // super admin menu
  horizontal_menu: {
    items: Partial<MenuItem>[]
  };
  // tenant menu
  vertical_menu: {
    items: Partial<MenuItem>[]
  };
  TENANT_MENU_ITEMS: {
    items: Partial<MenuItem>[]
  }
  AGENT_MENU_ITEMS: {
    items: Partial<MenuItem>[]
  }
  SUPERVISOR_MENU_ITEMS: {
    items: Partial<MenuItem>[]
  }
}

export const MenuSettingsConfig: MenuConfig = {
  horizontal_menu: {
    items: [
      //superadmin menu start
      {
        title: 'Dashboard',
        icon: 'la-home',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Account Dashboard',
              page: '/dashboard'
            },
            {
              title: 'Live Dashboard',
              page: '/superadmin/live-dashboard'
            },
            {
              title: 'Wallboard',
              page: '/superadmin/wallboard'
            }
          ]
        }
      },
      {
        title: 'Accounts',
        icon: 'feather ft-users',
        page: 'null',
        submenu: {
          items: [
            // {
            //   title: 'Roles & Permissions',
            //   page: '/superadmin/roles-permissions'
            // },
            {
              title: 'Admin',
              page: '/superadmin/admins'
            },
            {
              title: 'Tenants',
              page: '/superadmin/tenant-list',
            },
            {
              title: 'Email SMTP',
              page: '/superadmin/email-smtp',
            },
            {
              title: 'Mail Templates',
              page: '/superadmin/email-notification-templates',
            },
            // {
            //   title: 'Email & Notifications',
            //   page: '/superadmin/email-notification-templates',
            // },
            {
              title: 'Global Cron Jobs',
              page: '/superadmin/cron-jobs',
            },
          ]
        }
      },
      {
        title: 'Recordings',
        icon: 'la-headphones',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Recordings Manager',
              page: '/recordingsmanager/recordings'
            },

            {
              title: 'Recordings Backup',
              page: '/recordingsmanager/backup-manager'
            },
          ]
        },
      },
      {
        title: 'Reports & Statistics',
        icon: 'la-file-text',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Call Details Report',
              page: '/reports-statistics/call-details-report'
            },
            {
              title: 'Outbound Summary Report',
              page: '/reports-statistics/outbound-summary-report'
            },
            {
              title: 'Inbound Summary Report',
              page: '/reports-statistics/inbound-summary-report'
            },
            {
              title: 'Campaign Report',
              page: '/reports-statistics/campain-report'
            },
            // {
            //   title: 'Interactions History',
            //   page: '/recordingsmanager/under-dev'
            // },
            {
              title: 'Queue Report',
              page: '/reports-statistics/queue-report'
            },
            {
              title: 'Agent Disposition Report',
              page: '/reports-statistics/agent-disposition-report'
            },
            {
              title: 'Agent Summary Report Final',
              page: '/reports-statistics/agent-summary-report-final'
            },
            {
              title: 'Agent Perfomance Report',
              page: '/reports-statistics/agent-perfomance-report'
            },
            {
              title: 'Agent Sessions Report',
              page: '/reports-statistics/agent-session-report'
            },
            {
              title: 'DID Reporting',
              page: '/reports-statistics/did-reporting'
            },
            {
              title: 'Completed Call Backs Report',
              page: '/reports-statistics/completed-call-back-report'
            },
            {
              title: 'Scheduled Call Backs Report',
              page: '/reports-statistics/scheduled-call-back-report'
            },
            {
              title: 'Voice Brodcast Report',
              page: '/reports-statistics/voice-brodcast-report'
            },
            {
              title: 'SLA Abandoned Calls Report',
              page: '/reports-statistics/sla-abondoned-call-report'
            },
            {
              title: 'SLA Answered Calls Report',
              page: '/reports-statistics/sla-answered-call-reports'
            },
            {
              title: 'Interaction History Report',
              page: '/reports-statistics/interaction-history-report'
            },
            {
              title: 'SMS Report',
              page: '/reports-statistics/sms-report'
            },
            {
              title: 'IVR Report',
              page: '/reports-statistics/ivr-report'
            },
            // {
            //   title: 'Custom Reports',
            //   page: '/reports-statistics/scheduled-report-list'
            // },
            {
              title: 'Twitter Report',
              page: '/reports-statistics/twitter-report'
            },
            {
              title: 'Facebook Report',
              page: '/reports-statistics/facebook-report'
            },
            {
              title: 'Whatsapp Report',
              page: '/reports-statistics/whatsapp-report'
            },
            {
              title: 'Web Chat Report',
              page: '/reports-statistics/web-chat-report'
            },
          ]
        },
      },
      {
        title: 'System Statistics',
        icon: 'feather ft-monitor',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'System Statistics',
              page: '/systemstatics/system-statistic'
            },
            {
              title: 'System Status',
              page: '/systemstatics/system-status'
            },
            {
              title: 'Registered SIP Devices',
              page: '/systemstatics/registered-sip-devices'
            },
            {
              title: 'Gateways',
              page: '/systemstatics/gateway'
            },
          ]
        },
      },
      {
        title: 'Advanced Settings',
        icon: 'feather ft-settings',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Nodes',
              page: '/advancedsettings/under-dev'
            },
            {
              title: 'Proxy Nodes/ Container',
              page: '/advancedsettings/proxy-nodes'
            },
            {
              title: 'Media and Media relay Nodes / containers',
              page: '/advancedsettings/under-dev'
            },
            {
              title: 'Databases Management',
              page: '/advancedsettings/manage-database'
            },
            {
              title: 'Access Log',
              page: '/advancedsettings/access-log'
            },
          ]
        },
      },
      {
        title: 'Security',
        icon: 'feather ft-lock',
        // page: '/superadmin/security',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Fail2ban',
              page: '/superadmin/security'

            }
          ]
        }
      },
      // {
      //   title: 'Support',
      //   icon: 'la la-support',
      //   page: '/support/open-support-ticket',
      // },
      // {
      //   title: 'Profile Setting',
      //   icon: 'icon-user',
      //   page: '/profile',
      // },
    ]
  },
  vertical_menu: {
    items: [
      // // tenant menu start
      // { section: 'Tenant', icon: 'la-ellipsis-h' },
      {
        title: 'Dashboard',
        icon: 'la-home',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Account Dashboard',
              icon: 'la-home',
              page: '/dashboard',
            },
            {
              title: 'Live Dashboard',
              icon: 'icon-bar-chart',
              page: '/tenant/live-dashboard',
            },
            {
              title: 'Wallboard',
              icon: 'icon-paper-plane',
              page: '/tenant/wallboard',
            },
          ]
        }
      },
      {
        title: 'Agents & Teams',
        icon: 'icon-users',
        page: '/agentandteam',
      },
      {
        title: 'VOICE',
        icon: 'icon-volume-2',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Trunk',
              page: '/voice-manager/trunks'
            },
            {
              title: 'DID',
              page: '/voice-manager/dids'
            },
            {
              title: 'Music On Hold',
              page: '/voice-manager/mohs'
            },
            {
              title: 'Prompts & Announcements',
              page: '/voice-manager/prompts'
            },
            // {
            //   title: 'CLI Manager',
            //   page: '/voice-manager/cli-manager'
            // },
            // {
            //   title: 'Routing Options',
            //   page: '/voice-manager/under-dev'
            // },
            {
              title: 'FAX',
              page: '/voice-manager/fax-manager'
            },
            {
              title: 'Conferences',
              page: '/voice-manager/conference-list'
            },
            {
              title: 'Priority Numbers',
              page: '/voice-manager/high-priority-numbers'
            },
            {
              title: 'Time Conditions',
              page: '/voice-manager/time-conditions'
            },
          ]
        },
      },
      {
        title: 'SMS & EMAIL',
        icon: 'la-envelope',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'SMS Gateways',
              page: '/sms-email/apis-list'
            },
            {
              title: 'Mail SMTP',
              page: '/sms-email/smtp-config'
            },
            {
              title: 'Mail Templates',
              page: '/sms-email/mail-templates'
            }
          ]
        },
      },
      {
        title: 'Appointments',
        icon: 'la-calendar',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Sales Team',
              page: '/appointments/salesteam'
            },
            {
              title: 'Plannings',
              page: '/appointments/plannings'
            },
          ]
        },
      },
      {
        title: 'Queues',
        icon: 'feather ft-layers',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'IVR',
              page: '/queue/ivr-list'
            },
            {
              title: 'Call Queue',
              page: '/queue/queue-list'
            },
            {
              title: 'SMS Queue',
              page: '/queue/sms-queue'
            },
            {
              title: 'Email Queue',
              page: '/queue/email-queue'
            },
            {
              title: 'Chat Queue',
              page: '/queue/chat-queue'
            },
            {
              title: 'Social Queue',
              page: '/queue/social-queues',
            },
            {
              title: 'Open Ticket',
              page: '/queue/open-ticket-list'
            },
            {
              title: 'Closed Ticket',
              page: '/queue/closed-ticket-list'
            },
          ]
        },
      },
      {
        title: 'Campaigns',
        icon: 'la-bullhorn',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Outbound Campaign',
              page: '/campaigns/outbound-campaign-list'
            },
            {
              title: 'Inbound Campaign',
              page: '/campaigns/inbound-campaign-list'
            },
            {
              title: 'Blended Campaign',
              page: '/campaigns/blended-campaign-list'
            },
            // {
            //   title: 'Jobs',
            //   page: '/campaigns/jobs'
            // },
            {
              title: 'Dispositions',
              page: '/campaigns/dispositions'
            },
            {
              title: 'Scheduled Callback',
              page: '/campaigns/scheduled-callbacks'
            },
            {
              title: 'Completed Callback',
              page: '/campaigns/completed-callbacks'
            },
            // {
            //   title: 'Redial rules',
            //   page: '/campaigns/under-dev'
            // },
            {
              title: 'Typification',
              page: '/campaigns/typification'
            },
            {
              title: 'Voice Broadcast',
              page: '/campaigns/voice-broadcasts'
            },
            {
              title: 'Web Surveys',
              page: '/campaigns/web-surveys'
            },
            {
              title: 'SMS Campaign',
              page: '/campaigns/sms-campaigns'
            },
            {
              title: 'Email Campaigns',
              page: '/campaigns/mail-campaigns'
            }
            // {
            //   title: 'Ring Groups',
            //   page: '/pages/forms/ring-groups'
            // }
          ]
        },
      },
      {
        title: 'Security',
        icon: 'feather ft-lock',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'DNC List',
              page: '/security-manager/dnc-list'
            },
            {
              title: 'Black List',
              page: '/security-manager/blacklist'
            },
            {
              title: 'Fraud detection',
              page: '/security-manager/fraud-detection'
            }
          ]
        },
      },
      {
        title: 'Contact Management',
        icon: 'la-book',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'CRM',
              icon: 'grid-outline',
              page: '/contact-management/list',
            },
            {
              title: 'Contact List',
              page: '/contact-management/contact-list'
            },
            {
              title: 'Contact Management',
              page: '/contact-management/contact-management'
            },
            {
              title: 'Filters',
              page: '/contact-management/filter'
            },
          ]
        },
      },
      {
        title: 'SCRIPT',
        page: '/script-builder',
        icon: 'la-stack-exchange',
      },
      {
        title: 'Voicemail',
        icon: 'feather ft-voicemail',
        page: '/agent/voicemails'
      },
      {
        title: 'Omnichannel',
        icon: 'icon-social-facebook',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Smooch',
              page: '/omnichannel/smooch-plateform-list',
            },
            {
              title: 'Whatsapp',
              page: '/omnichannel/whatsapp-configuration-list',
            },
            {
              title: 'Facebook',
              page: '/omnichannel/facebook-configuration-list',
            },
            {
              title: 'Twitter',
              page: '/omnichannel/twitter-congfigs',
            },
            {
              title: 'Facebook Accounts',
              page: '/omnichannel/facebook-post-account',
            },
            {
              title: 'Twitter Accounts',
              page: '/omnichannel/manage-twitter-post-accounts',
            }
          ]
        },
      },
      {
        title: 'Reports & Statistics',
        icon: 'la-file-text',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Call Details Report',
              page: '/reports-statistics/call-details-report'
            },
            {
              title: 'Outbound Summary Report',
              page: '/reports-statistics/outbound-summary-report'
            },
            {
              title: 'Inbound Summary Report',
              page: '/reports-statistics/inbound-summary-report'
            },
            {
              title: 'Campaign Report',
              page: '/reports-statistics/campain-report'
            },
            // {
            //   title: 'Interactions History',
            //   page: '/recordingsmanager/under-dev'
            // },
            {
              title: 'Queue Report',
              page: '/reports-statistics/queue-report'
            },
            {
              title: ' Agent Disposition Report',
              page: '/reports-statistics/agent-disposition-report'
            },
            {
              title: ' Agent Summary Report Final',
              page: '/reports-statistics/agent-summary-report-final'
            },
            {
              title: 'Agent Perfomance Report',
              page: '/reports-statistics/agent-perfomance-report'
            },
            {
              title: 'Agent Sessions Report',
              page: '/reports-statistics/agent-session-report'
            },
            {
              title: 'DID Reporting',
              page: '/reports-statistics/did-reporting'
            },
            {
              title: 'Completed Call Backs Report',
              page: '/reports-statistics/completed-call-back-report'
            },
            {
              title: 'Scheduled Call Backs Report',
              page: '/reports-statistics/scheduled-call-back-report'
            },
            {
              title: ' Voice Brodcast Report',
              page: '/reports-statistics/voice-brodcast-report'
            },
            {
              title: ' SLA Abandoned Calls Report',
              page: '/reports-statistics/sla-abondoned-call-report'
            },
            {
              title: ' SLA Answered Calls Report',
              page: '/reports-statistics/sla-answered-call-reports'
            },
            {
              title: ' Interaction History Report',
              page: '/reports-statistics/interaction-history-report'
            },

            {
              title: 'SMS Report',
              page: '/reports-statistics/sms-report'
            },
            {
              title: 'IVR Report',
              page: '/reports-statistics/ivr-report'
            },
            {
              title: 'Custom Reports',
              page: '/reports-statistics/scheduled-report-list'
            },
            {
              title: 'Twitter Report',
              page: '/reports-statistics/twitter-report'
            },
            {
              title: 'Facebook Report',
              page: '/reports-statistics/facebook-report'
            },
            {
              title: 'Whatsapp Report',
              page: '/reports-statistics/whatsapp-report'
            },
            {
              title: 'Web Chat Report',
              page: '/reports-statistics/web-chat-report'
            },
          ]
        },
      },
      {
        title: 'Recordings',
        icon: 'la-headphones',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Recordings Manager',
              page: '/recordingsmanager/recordings'
            },

            {
              title: 'Recordings Backup',
              page: '/recordingsmanager/backup-manager'
            },
          ]
        },
      },
      {
        title: 'Quality Management',
        icon: 'la-balance-scale',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Agent Performance Evaluation',
              page: '/qualitymanager/agent-performance'
            },
            // {
            //   title: 'Call Quality Evaluation',
            //   page: '/qualitymanager/call-quality-evaluation'
            // },
            {
              title: 'From Template',
              page: '/qualitymanager/from-template'
            },
            {
              title: 'Call Quality Evaluation',
              page: '/qualitymanager/call-evaluation-form'
            },
            // {
            //   title: 'Customer Satisfaction',
            //   page: '/qualitymanager/customer-satisfaction'
            // },
            {
              title: 'Survey Template',
              page: '/qualitymanager/survey-template'
            },
            {
              title: 'Customer Survey',
              page: '/qualitymanager/customer-survey'
            },

          ]
        },
      },
      // {
      //   title: 'BOT',
      //   icon: 'la-reddit-square',
      //   page: 'null',
      //   submenu: {
      //     items: [
      //       {
      //         title: 'BOT Setting',
      //         page: '/botmanager/bot-setting'
      //       },
      //       // {
      //       //   title: 'Preview',
      //       //   page: '/botmanager/bot-preview'
      //       // },
      //     ]
      //   },
      // },
      {
        title: 'Integrations',
        icon: 'feather ft-cpu',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Click-to-Call',
              page: '/integrations/click-to-call'
            },
            {
              title: 'API',
              page: '/integrations/apis'
            },
            {
              title: 'Salesforce',
              page: '/integrations/sales-force'
            },
            {
              title: 'Zoho',
              page: '/integrations/zoho'
            },
            {
              title: 'Odoo',
              page: '/integrations/odoo'
            },
            {
              title: 'Zendesk',
              page: '/integrations/zendesk'
            },
            {
              title: 'Bitrix24',
              page: '/integrations/bitrix'
            },
            {
              title: '3rd Party SQL Database Integration',
              page: '/integrations/sql-database'
            },
          ]
        },
      },
      {
        title: 'Support',
        icon: 'la-support',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Support',
              page: '/supportmanager/manage'
            },
            {
              title: 'Documentation',
              page: '/supportmanager/under-dev'
            },
          ]
        },
      },
      //      {
      //   title: 'Profile Setting',
      //   icon: 'icon-user',
      //   page: '/profile',
      // },
    ]
  },
  TENANT_MENU_ITEMS: {
    items: [

    ]
  },
  AGENT_MENU_ITEMS: {
    items: [
      {
        title: 'Dashboard',
        icon: 'la-home',
        page: '/dashboard',
      },
      {
        title: 'Call',
        icon: 'la-phone',
        page: '/agent/call'
      },
      {
        title: 'Appointments',
        icon: 'la-calendar-o',
        page: '/agent/appointments',
      },
      // {
      //   title: 'Chat',
      //   icon: 'la-comments',
      //   page: '/chats/static-chat',
      // },
      {
        title: 'SMS',
        icon: 'feather ft-message-square',
        page: '/agent/sms-agent',
      },
      // {
      //   title: 'Whatsapp',
      //   icon: 'la-whatsapp',
      //   page: '/agent/whatsapp',
      // },
      // {
      //   title: 'Facebook',
      //   icon: 'icon-social-facebook',
      //   page: '/agent/facebook',
      // },
      // {
      //   title: 'Twitter',
      //   icon: 'la-twitter',
      //   page: '/agent/twitter',
      // },
      // {
      //   title: 'Tickets',
      //   icon: 'la-ticket',
      //   page: '/agent/tickets'
      // },
      {
        title: 'Email',
        icon: 'feather ft-mail',
        page: '/agent/email-chat',
      },
      {
        title: 'Quality Feedback',
        icon: 'la-balance-scale',
        page: '/agent/quality-feedback',
      },
      {
        title: 'Agent Report',
        icon: 'la-file-text',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Summary Report',
              page: '/agent/summary-report'
            },
            {
              title: 'CDR',
              page: '/supportmanager/under-dev'
            },
          ]
        }
      }
      // {
      //   title: 'Profile Setting',
      //   icon: 'icon-user',
      //   page: '/profile',
      // },
    ]
  },
  SUPERVISOR_MENU_ITEMS: {
    items: [
      {
        title: 'Dashboard',
        icon: 'la-home',
        page: '/dashboard',
      },
      {
        title: 'Call',
        icon: 'la-phone',
        page: '/agent/call'
      },
      {
        title: 'Voicemail',
        icon: 'feather ft-voicemail',
        page: '/agent/voicemails'
      },
      {
        title: 'Callbacks',
        icon: 'feather ft-phone-outgoing',
        page: '/agent/callbacks'
      },
      {
        title: 'Parked Calls',
        icon: 'feather ft-phone-call',
        page: '/agent/parked-call'
      },
      {
        title: 'Appointments',
        icon: 'la-calendar-o',
        page: '/agent/appointments',
      },
      // {
      //   title: 'Chat',
      //   icon: 'la-comments',
      //   page: '/chats/static-chat',
      // },
      {
        title: 'SMS',
        icon: 'feather ft-message-square',
        page: '/agent/sms-agent',
      },
      {
        title: 'Manage Team',
        icon: 'la-users',
        page: '/agent/manage-team'
      },
      // {
      //   title: 'Active Calls',
      //   icon: 'la-line-chart',
      //   page: '/agent/live-calls'
      // },
      // {
      //   title: 'Whatsapp',
      //   icon: 'la-whatsapp',
      //   page: '/agent/whatsapp',
      // },
      // {
      //   title: 'Facebook',
      //   icon: 'icon-social-facebook',
      //   page: '/agent/facebook',
      // },
      // {
      //   title: 'Twitter',
      //   icon: 'la-twitter',
      //   page: '/agent/twitter',
      // },
      {
        title: 'Tickets',
        icon: 'la-ticket',
        page: '/agent/tickets'
      },
      {
        title: 'Email',
        icon: 'feather ft-mail',
        page: '/agent/email-chat',
      },
      {
        title: 'Quality Feedback',
        icon: 'la-balance-scale',
        page: '/agent/quality-feedback',
      },
      {
        title: 'Agent Report',
        icon: 'la-file-text',
        page: 'null',
        submenu: {
          items: [
            {
              title: 'Summary Report',
              page: '/agent/summary-report'
            },
            {
              title: 'CDR',
              page: '/agent/agent-cdr'
            },
          ]
        }
      }
      // {
      //   title: 'Profile Setting',
      //   icon: 'icon-user',
      //   page: '/profile',
      // },
    ]
  }
};
