export const environment = {
  production: false,
  PROJECT_NAME: 'sparrow',

  API_URL: 'https://apps.cexlabapps.com:10707/',
  AGENT_QUEUE_URL: 'https://apps.cexlabapps.com:20707/',
  SOCIAL_MEDIA_URL: 'https://apps.cexlabapps.com:30707/',
  DOMAIN_URL: 'https://apps.cexlabapps.com',
  wsEndpoint: 'wss://apps.cexlabapps.com/main',
  SocketIOChatUrl: 'https://apps.cexlabapps.com:9000/',

  // ####### VI-callibry ###################s


  // API_URL: 'https://vi.callibry.com:3001/',
  // AGENT_QUEUE_URL: 'https://vi.callibry.com:3002/',
  // SOCIAL_MEDIA_URL: 'https://vi.callibry.com:30707/',
  // DOMAIN_URL: 'https://vi.callibry.com',
  // wsEndpoint: 'wss://vi.callibry.com/main',
  // SocketIOChatUrl: 'https://vi.callibry.com:9000/',

  ProxyUrl: 'telephony.telemo.io',
  ProxyPort:'7443',
  LOG: (e) => console.log(e),
  ERROR: (e) => console.error(e),
  firebase: {
    apiKey: 'AIzaSyAvHtu5rrqKSZQg75R2MYqlNgGdMKy2YR4',
    projectId: 'modern-admin-8453e',
    authDomain: 'modern-admin-8453e.firebaseapp.com',
    appId: '1:300550201463:web:96a271cc309ac5aea86672'
  },
  googleApiKey: 'AIzaSyAIIYOxA7qeetFz6TuR1Qewc0Rrjhzx7ZU'
};