import { v4 as uuidv4 } from "uuid";
import { Lightbox } from "ngx-lightbox";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { APIService } from "../_services/api.service";
import { AuthService } from "../_services/auth.service";
import { DomSanitizer } from "@angular/platform-browser";
import { UtilsService } from "../_services/utils.service";
import { WebsocketService } from "../_services/websocket.service";
import { Component, OnInit, ViewChild, HostListener } from "@angular/core";
import { FormValidationService } from "../_services/form-validation.service";
import { FormControl, FormGroup, FormBuilder, Validators } from "@angular/forms";
import { environment } from "./../../../src/environments/environment.prod"
import {ActivatedRoute} from '@angular/router';

declare var $: any;
@Component({
  templateUrl: "login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  domain_url = environment.DOMAIN_URL;
  modalimage: any;

  // integration_url = environment.INTEGRATION_ID;
  receiver_ext_id:any;
  thread_id:any = '';
  loginForm: FormGroup;
  submitted = false;
  loading = false;
  returnUrl: string;
  integration_url :any;
  domain_uuid :any;
  contactTwitter: any;
  First_Name:any;
  newMessage: FormControl = new FormControl("");
  newMsg = '';
  public dm_messages:any = [];

  TwitterMessages: any;
  self_user_details: any = {
    role: "customer",
  };
  messages: Array<any> = [];
  receiver_user_id: string;
  @ViewChild("box") input_box;
  imoji_box_open: Boolean = false;
  customer_user_details: FormGroup;
  show_customer_form: Boolean = true;
  fileToUpload: File = null;
  image_URL: any;
  img_preview_collection: Array<any> = [];
  image_file_type: Array<string> = [
    "png",
    "jpeg",
    "jpg",
    "ico",
    "svg",
    "svg+xml",
    "vnd.microsoft.icon",
    "image/gif",
    "image/png",
    "image/jpeg",
    "image/jpg",
    "image/svg+xml",
    "image/vnd.microsoft.icon",
  ];
  user_id: any;
  customer_name: any;
  @HostListener("document:click", ["$event"])
  on_click(event: MouseEvent) {
    this.imoji_box_open = false;
  }
  // For media img
  fileName: any;
  file_format: any;
  fileType: any;
  selectedFile: File = null;
  uploadForm: FormGroup;
  imageURL: string;
  media_URL: string | ArrayBuffer;
  localurl : any;
  url : any;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    public utils: UtilsService,
    private lightbox: Lightbox,
    private toastr: ToastrService,
    private apiService: APIService,
    public authService: AuthService,
    private websocket: WebsocketService,
    public form_validator: FormValidationService,
    public route : ActivatedRoute
  ) {
    this.customer_user_details = this.fb.group({
      name: ["",[Validators.required, Validators.pattern(new RegExp("^[a-zA-Z\\.\\s]+$"))],],
      email: ["", [Validators.email]],
      phone_num:['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.maxLength(12)]],
    });
  }

  ngOnInit() {
    // this.listen_socket_events();
    
        // this.integration_url = '3a16659442574807bd5a942668d22e28';
        // this.domain_uuid = '4bee1628-68e8-11ee-962b-0a30e19ca8fe';
        // console.log(this.integration_url,"this.integration_url");
        // console.log(this.domain_uuid,"this.domain_uuid")
    this.route.queryParams
      .subscribe(params => {
        console.log(params); // { orderby: "price" }
        this.integration_url = params.integration_id;
        this.domain_uuid = params.domain_uuid;
      }
    );

    this.TwitterMessages = [];
    if (localStorage.getItem("currentUser")) this.authService.doLogout();
    // this.receiveMessageBySocket();
    // if(localStorage.getItem('joinUser') != undefined)
    // {
    // } else{
    // } 
  }

  receiveMessageBySocket(){
    this.websocket.listen('webchat-messages').subscribe((data:any) => {
      this.scrollToBottom();
      console.log("webchat-messages    ==-->", data.WebchatMessageList);
      this.dm_messages = data.WebchatMessageList;
      console.log(this.dm_messages, "message_btn")
    });
  }
  get formErrorAlert() { return this.customer_user_details.controls; }


  /**
   * Create a socket to send and receive real time message
   */
  submitcustomerDetails() {
    this.submitted = true;
    if (this.customer_user_details.invalid) {
      // this.toastr.error('Please enter your valid details', "Error!", { progressBar: true });
      return;
    }
      this.customer_name = this.customer_user_details.value["name"];
      this.apiService.joinUser().subscribe((response:any)=>{
        if(response['Status'] == 0){
          this.toastr.error(response["Msg"], "Error!", { progressBar: true });
        }else{
        this.user_id = response.user_id;
        this.show_customer_form = false;
        const params = {
          user_id: response.user_id,
          domain_uuid : this.domain_uuid,
          emailId: this.customer_user_details.value["email"],
          last_name: this.customer_user_details.value["name"],
          role: 'customer',
          short_name: this.customer_user_details.value["name"].toUpperCase().charAt(0),
          agent_uuid: '',
          phone:this.customer_user_details.value["phone_num"],
        }
        this.websocket.emit("joinUser", params);
          this.websocket.listen('usersStatus').subscribe((data:any) => {
          this.First_Name =this.customer_user_details.value["name"]
          this.apiService.JoinUserId(this.integration_url,this.user_id,this.First_Name,).subscribe((response))
          // setTimeout(() => {
          //   this.isLoading = false;
          // }, 1000);
        })
        this.receiveMessageBySocket();
        }
      })
    // const name = this.customer_details_controls.name.value;
    // const user_id = this.customer_details_controls.email.value;

    // this.self_user_details = { name, user_id };
    // const short_name: string = this.utils.short_name_finder(name);
    // let space_seprated_name: Array<string> = name.split(/\s+/);
    // const parmas = {
    //   short_name,
    //   user_id,
    //   role: "customer",
    //   emailId: space_seprated_name[0],
    //   roomName: "customer",
    //   limit: 25,
    //   last_name: space_seprated_name[space_seprated_name.length - 1],
    // };
    // console.log(parmas , "parmas")
    // this.websocket.emit("join", parmas);

    // const query = {
    //   customer_name: name,
    //   customer_mail: user_id,
    //   customer_uuid: uuidv4(),
    // }
    // setTimeout(()=>{
    //   this.websocket.emit("widget_query",{
    //   customer_name: name,
    //   customer_mail: user_id,
    //   customer_uuid: uuidv4(),
    // });
    // },2000)
  }

  /**
   * Multiple listener listen for event from  server
   */
  // listen_socket_events() {
  //   this.websocket.listen("receivedMessage").subscribe((data: any) => {
  //     const {
  //       message,
  //       messageID: message_id,
  //       time,
  //       user_id,
  //       short_name,
  //       arraybuffer: image,
  //     } = data;
  //     this.messages.push({
  //       time,
  //       image,
  //       message,
  //       short_name,
  //       message_id,
  //       sender_user_id: user_id,
  //     });
  //   });

  //   this.websocket.listen("agentAssigned").subscribe((data: any) => {
  //     const { agent_name, agent_email , agent_uuid ,agent_extension} = data;
  //     this.toastr.info("Info", `Agent ${agent_name} has assigned to you`);
  //     this.show_customer_form = false;
  //     this.receiver_user_id = agent_email;
  //     this.receiver_ext_id = agent_extension;
  //   });
  // }

  /**
   * Send message to other user
   */


   send_message_to_agent() {
    if (this.selectedFile) {
      if (this.selectedFile.type.startsWith('image/')) {
        this.fileType = 'image';
      } else if (this.selectedFile.type.startsWith('video/')) {
        this.fileType = 'video';
      } else if (this.selectedFile.type.startsWith('audio/')) {
        this.fileType = 'audio';
      } else if (this.selectedFile.type.startsWith('application/pdf')) {
        this.fileType = 'document';
      } else if (this.selectedFile.type.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
        this.fileType = 'document';
      } else if (this.selectedFile.type.startsWith('text/csv')) {
        this.fileType = 'document';
      // } else if (this.selectedFile.type.startsWith('application/x-zip-compressed')) {
        // this.fileType = 'document';
      } else if (this.selectedFile.type.startsWith('application/zip') || this.selectedFile.type.startsWith('application/x-zip-compressed')) {
        this.fileType = 'document';
      }else if (this.selectedFile.type.startsWith('application/msword') || this.selectedFile.type.startsWith('application/docx' )|| this.selectedFile.type.startsWith('application/doc' )|| this.selectedFile.type.startsWith('application/docm' ) || this.selectedFile.type.startsWith('application/dot' )  || this.selectedFile.type.startsWith('application/vnd.openxmlformats-officedocument.wordprocessingml.document' )) {
        this.fileType = 'document';
      }else {
        this.toastr.error('Only jpeg, png, mp4, mp3, wav, pdf,Doc and zip formats are allowed', 'Error!');
        return;
      }
      this.FileDataSend();
    }
    const currentDate = new Date();
    if (this.newMsg !== null && this.newMsg !== '') {
      this.send_messages(this.newMsg);
      this.dm_messages.push({
        message: this.newMsg,
        time: currentDate,
        app_owner: false,
        name: '',
        mime_type: '',
        from: {
          name: '',
          id: ''
        }
      });
      this.newMsg = null;
      // this.content.nativeElement.scrollTop = this.content.nativeElement.scrollHeight;
    }
    this.scrollToBottom();
  }
  send_messages(message) {
    const params = {
      thread_id: this.thread_id,
      integration_id: this.integration_url,
      agent_uuid: '',
      customer_name: this.customer_user_details.value["name"],
      phone: this.customer_user_details.value["phone_num"],
      email: this.customer_user_details.value["email"],
      message: message,
      user_id: this.user_id
    }
    this.apiService.sendMessage(params).subscribe((response) => {
    
      this.scrollToBottom();
    });
  }
  
  scrollToBottom(){
    setTimeout(()=>{
      // var objDiv = document.getElementById("contentDiv");
      // objDiv.scrollTop = objDiv.scrollHeight;
      this.utils.scroll_to_bottom("message_container");
    },500)
  }
  // send_message_to_agent() {
  //   let message: string = this.utils.remove_quotes(this.newMessage.value);
  //   message = message.trimLeft().length != 0 ? message : message.trimLeft();

  //   if (!message.length && !this.image_URL) {
  //     this.newMessage.setValue("");
  //     return;
  //   }

  //   const { name, role, user_id } = this.self_user_details;
  //   const time: number = new Date().getTime();
  //   const short_name = this.utils.short_name_finder(name);
  //   const messageID = this.utils.creat_uuid;
  //   const sendMessageObj = {
  //     role:'customer',
  //     message,
  //     time,
  //     short_name,
  //     messageID,
  //     from: name,
  //     to: this.receiver_ext_id,
  //     replied_uuid: "-1",
  //     replied_type: "original",
  //     user_id: user_id,
  //     typeofmessage: "private",
  //     status: "live",
  //     live: "true", 
  //   };
  //   if (!this.image_URL) {
  //     this.websocket.emit("sendMessage", sendMessageObj);
  //     this.messages.push({
  //       time,
  //       message,
  //       short_name,
  //       sender_user_id: user_id,
  //       message_id: messageID,
  //       image: undefined,
  //     });
  //   } else if (this.image_URL) {
  //     this.websocket.emit("sendImage", {
  //       ...sendMessageObj,
  //       fileName: this.fileToUpload.name,
  //       size: this.fileToUpload.size,
  //       arraybuffer: this.fileToUpload,
  //     });
  //     this.messages.push({
  //       time,
  //       message,
  //       short_name,
  //       sender_user_id: user_id,
  //       message_id: messageID,
  //       image: window.URL.createObjectURL(
  //         new Blob([this.fileToUpload], { type: this.fileToUpload.type })
  //       ),
  //     });
  //   }
  //   this.newMessage.setValue("");
  //   this.image_URL = undefined;
  //   this.img_preview_collection = [];
  //   setTimeout(() => {
  //     this.utils.scroll_to_bottom("message_container");
  //   }, 500);
  // }

  // chat code ends from here
  setUserInStorage(res) {
    if (res.account_type === "Tenant") {
      localStorage.setItem("currentUser", JSON.stringify(res));
      sessionStorage.removeItem("show_superuser_login_icon");
    } else if (res.account_type === "Superuser") {
      localStorage.setItem("superuser_user", JSON.stringify(res));
      localStorage.setItem("currentUser", JSON.stringify(res));
      sessionStorage.removeItem("show_superuser_login_icon");
    } else {
      localStorage.setItem("currentUser", JSON.stringify(res));
      sessionStorage.removeItem("show_superuser_login_icon");
    }
  }

  add_emoji(event) {
    let message: string = this.newMessage.value;
    if (event.emoji.native) this.newMessage.setValue(`${message}${event.emoji.native}`);
  }

  open_image(imageUrl) {
    this.lightbox.open(
      [
        {
          src: <string>this.sanitizer.bypassSecurityTrustUrl(imageUrl),
          thumb: imageUrl,
        },
      ],
      0,
      {
        centerVertically: true,
      }
    );
  }

  handleFileInput(files: FileList) {
    this.imoji_box_open = false;
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      this.image_URL = reader.result;
      this.fileToUpload = files.item(0);
      this.img_preview_collection[0] = {
        fileName: this.fileToUpload["name"],
        imageUrl: window.URL.createObjectURL(
          new Blob([this.fileToUpload], { type: this.fileToUpload["type"] })
        ),
        size: this.utils.calculate_file_size(this.fileToUpload["size"]),
        type: this.fileToUpload["type"],
      };
      // this.input_box.focus();
    };
  }

  close_file_preview(index: number) {
    this.img_preview_collection.splice(index, 1);
    this.image_URL = undefined;
    this.fileToUpload = null;
  }

  openInLarge(url) {
    this.modalimage = url;
    document.getElementById('myModal').style.display = 'block';
  }
  closeModalImage() {
    document.getElementById('myModal').style.display = 'none';
    document.getElementById('myModallocal').style.display = 'none';
  }
  openInLargelocal(url){
    this.localurl = url;
    document.getElementById('myModallocal').style.display = 'block';
  }
  // select media files
  onSelectedFile(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.selectedFile = <File>event.target.files[0];
      this.fileName = file.name;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        this.url = (<FileReader>event.target).result;
        this.url = this.sanitizer.bypassSecurityTrustUrl(this.url);
      }
      
      this.media_URL = reader.result; 
      this.file_format = file.type;

      if(this.selectedFile.size/1024 > 16000 && (file.type === 'video/mp4' || file.type === 'video/3gp')) {
        this.selectedFile = null;
        return this.toastr.error('Video size should be smaller then 16 MB', 'Error!');
     
      }
      this.fileType = file.type;
      // this.FileDataSend();
    }
  }

  // API for sending media files start from here

  FileDataSend() {
    const formData = new FormData();
    formData.append('integration_id', this.integration_url);
    formData.append('agent_uuid', '');
    formData.append('customer_name', this.customer_user_details.value["name"]);
    formData.append('phone', this.customer_user_details.value["phone_num"]);
    formData.append('email', this.customer_user_details.value["email"]);
    formData.append('message', '');
    formData.append('user_id',this.user_id);
    formData.append('thread_id', this.thread_id);

    formData.append('mime_type',this.file_format);
    formData.append('file', this.selectedFile);
    formData.append('file_type', this.fileType);

    this.apiService.sendMediaFile(formData).subscribe((response) => {
      this.scrollToBottom();
      this.fileName = '';
      this.fileType = '';
      this.selectedFile = null;
      // this.refreshChatMessages();

    });
 
  }
  removeAttachment() {
    this.selectedFile = null;
   
}

    // convenience getter for easy access to form fields
    get f() {
      return this.loginForm.controls;
    }
  
    get customer_details_controls() {
      return this.customer_user_details.controls;
    }
  
    tryLogin() {
      this.submitted = true;
      if (this.loginForm.invalid) return;
  
      this.loading = true;
      const data = {
        username: this.f.email.value,
        password: this.f.password.value,
      };
      this.apiService.login(data).subscribe((response) => {
        if (response["Status"] === "1") {
          // get agent roles
          // domain_uuid
          this.setUserInStorage(response);
          localStorage.removeItem("currentLayoutStyle");
          let returnUrl = "/dashboard/sales";
          if (this.returnUrl) returnUrl = this.returnUrl;
          this.router.navigate([returnUrl]);
        } else {
          this.loading = false;
          this.submitted = false;
          this.toastr.error(response["Msg"], "Error!", { progressBar: true });
        }
      });
    }

    myURL(url){
      window.open('https://'+url)
    }

    changeBotBtn(value){
      this.newMsg = value;
      this.send_message_to_agent();
    }
  
    parseMessage(message) {
      if(message) {
     return JSON.parse(message);
      }
    }
}
