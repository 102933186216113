import { Component, OnDestroy, HostBinding, OnInit, Inject, Renderer2, ElementRef, ViewChild, AfterViewInit, HostListener, ComponentFactoryResolver } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavbarService } from '../../../_services/navbar.service';
import { ThemeSettingsService } from '../../settings/theme-settings.service';
import { MenuSettingsService } from '../../settings/menu-settings.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from '../../../../app/_services/auth.service';
import { Router } from '@angular/router';
import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { AppConstants } from '../../../../app/_helpers/app.constants';
import { APIService } from '../../../_services/api.service';
import { ToastrService } from 'ngx-toastr';
import { getTime } from 'date-fns';
declare var $: any;
declare var Telemo: any;
declare var window: any;

const docElmWithBrowsersFullScreenFunctions = document.documentElement as HTMLElement & {
  mozRequestFullScreen(): Promise<void>;
  webkitRequestFullscreen(): Promise<void>;
  msRequestFullscreen(): Promise<void>;
};

const docWithBrowsersExitFunctions = document as Document & {
  mozCancelFullScreen(): Promise<void>;
  webkitExitFullscreen(): Promise<void>;
  msExitFullscreen(): Promise<void>;
};
@Component({
  selector: 'app-header-vertical',
  templateUrl: './vertical.component.html',
  styleUrls: ['./vertical.component.scss']
})
export class VerticalComponent implements OnInit, AfterViewInit {
  getTimeData: any;
  insideTm: any;
  outsideTm: any;
  // isDisabled=true;
  updateButtonShow: boolean = true;
  camp_list_showing: boolean = true;
  currentBreak = "Available";
  avialability_check: any;
  private _unsubscribeAll: Subject<any>;
  private _unsubscribeAllMenu: Subject<any>;
  public _themeSettingsConfig: any;
  private _menuSettingsConfig: any;
  public selectedHeaderNavBarClass: string;
  public selectedNavBarHeaderClass: string;
  public currentUser: any;
  public isHeaderSearchOpen: any;
  isMobile = false;
  showNavbar = false;
  public maximize: any;
  public search: any;
  public internationalization: any;
  public notification: any;
  public email: any;
  public config: PerfectScrollbarConfigInterface = { wheelPropagation: false };
  @ViewChild(PerfectScrollbarComponent, { static: false }) componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: true }) directiveRef?: PerfectScrollbarDirective;
  // new declaration 
  dataOfUser: any;
  returnUrl: string;
  showBackToSuperUserLoginIcon = false;
  currentDate: any;
  showSettingIcon = false;
  allBreaksData: any;
  noDataFound = false;
  showBreakOption = false;
  showCampaignsOptions = false;
  agentMediaDropdown: any;
  //   currentBreak
  // _default_value
  // : any;
  no_camp_list_showing: boolean = false;
  sessionCheck: any;
  stopCheckingBreak: any;
  allCampaignsData: any;
  soicalQueueData: any;
  camp_data_check: any;
  available_list: any;
  isAgentReady = false;
  set_outbound_camp_uuid: any;
  selectedInbound: number = Number(0);
  selectedOutbound: number = Number(0);
  selectedBlended: number = Number(0);
  seconds: number;
  minutes: number;
  hours: number;
  readyOpenPhoneStatus: any;
  // time counter 
  // time: number = 0;
  display: any = "00:00:00";
  interval: any = 0;
  time: any = 0;
  timer;

  readyInterval: any = 0;
  Readytime: any = 0;
  waitingInterval: any = 0;
  waitingtime: any = 0;
  BreakInterval: any = 0;
  Breaktime: any = 0;
  chatInterval: any = 0;
  chattime: any = 0;
  smsInterval: any = 0;
  smstime: any = 0;
  emailInterval: any = 0;
  emailtime: any = 0;
  facebookInterval: any = 0;
  facebooktime: any = 0;
  whatsappInterval: any = 0;
  whatsapptime: any = 0;
  twitterInterval: any = 0;
  twittertime: any = 0;
  show_Resume_call = false;
  show_pause_call = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private _renderer: Renderer2,
    private navbarService: NavbarService,
    private _themeSettingsService: ThemeSettingsService,
    private _menuSettingsService: MenuSettingsService,
    public authService: AuthService,
    private router: Router,
    private elementRef: ElementRef,
    private apiService: APIService,
    private toastr: ToastrService,
  ) {
    this._unsubscribeAll = new Subject();
    this._unsubscribeAllMenu = new Subject();
  }

  // logout() {
  //   this.authService.doLogout();
  // }

  ngOnInit() {
    sessionStorage.removeItem("buttonStore")
    if (this.currentBreak === "Available") {
      this.startStatusTimer();
    } else {
    }

    this.available_list = [
      { name: 'available' },
      { name: 'acw' },
    ]
    this.agentMediaDropdown = [
      { name: 'Chat' },
      { name: 'Email' },
      { name: 'SMS' },
      { name: 'Whatsapp' },
      { name: 'Facebook' },
      { name: 'Twitter' },
    ]
    this.dataOfUser = JSON.parse(localStorage.getItem('currentUser'));
    //  alert(this.dataOfUser.account_type)

    this.isMobile = window.innerWidth < AppConstants.MOBILE_RESPONSIVE_WIDTH;
    if (!this.isMobile) {
      this.showNavbar = true;
    }
    if (localStorage.getItem('currentUser')) {
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }
    // Subscribe to config changes
    this._themeSettingsService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this._themeSettingsConfig = config;
        this.refreshView();
      });
    this._menuSettingsService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this._menuSettingsConfig = config;
      });
    this.maximize = this._themeSettingsConfig.headerIcons.maximize;
    this.search = this._themeSettingsConfig.headerIcons.search;
    this.internationalization = this._themeSettingsConfig.headerIcons.internationalization;
    this.notification = this._themeSettingsConfig.headerIcons.notification;
    this.email = this._themeSettingsConfig.headerIcons.email;

    this.showBackToSuperUserLogin();
    setInterval(() => {
      this.currentDate = new Date();
    }, 1);

    if (this.dataOfUser.account_type == 'Supervisor') {
      if (this.document.body.classList.contains('menu-expanded')) {
        // show the left aside menu
        this.navbarService.setFixedMenu(false);
        this.document.body.classList.remove('menu-expanded');
        this.document.body.classList.add('menu-collapsed');
        this._themeSettingsConfig.menu = 'collapse';
      }
    } else if (this.dataOfUser.account_type == 'Tenant' || this.dataOfUser.account_type == 'Superuser') {
    }

    this.getAvailableCampaign();
    this.manageBreakOptions();
    // set agent sip status on dialer 
    $(".agent_registered").hide();
    $(".agent_unregistered").show();
    $("#agent_is_registered").css("background", "#f10c0c");
    // this.startTimer();
  }


  selectDropValue(data) {
    const telemoSdk = new Telemo();
    telemoSdk.unRegisterUser();
    sessionStorage.setItem("media_status", data);
    $("#ready_call_timer").css({ "display": "none" });
    $("#waiting_call_timer").css({ "display": "none" });
    $("#acwTime").css({ "display": "none" });

    this.ReadyResetTimer();
    this.BreakResetTimer();
    this.waitingResetTimer();
    if (data === 'Chat') {
      $("#agentCallStatus").text('Chat');
      $("#sms_call_timer").css({ "display": "none" });
      this.chatReadyResetTimer();
      this.chatStatusTimer();
      $("#chat_call_timer").css({ "display": "block" });

      $("#email_call_timer").css({ "display": "none" });
      $("#facebook_call_timer").css({ "display": "none" });
      $("#whatsapp_call_timer").css({ "display": "none" });
      $("#twitter_call_timer").css({ "display": "none" });
    } else if (data === 'Email') {
      $("#sms_call_timer").css({ "display": "none" });
      $("#chat_call_timer").css({ "display": "none" });
      $("#email_call_timer").css({ "display": "block" });
      $("#agentCallStatus").text('Email');
      this.emailResetTimer();
      this.emailStatusTimer();
      $("#facebook_call_timer").css({ "display": "none" });
      $("#whatsapp_call_timer").css({ "display": "none" });
      $("#twitter_call_timer").css({ "display": "none" });
    } else if (data === 'SMS') {
      $("#agentCallStatus").text('SMS');
      $("#sms_call_timer").css({ "display": "block" });
      $("#chat_call_timer").css({ "display": "none" });
      $("#email_call_timer").css({ "display": "none" });
      this.smsResetTimer();
      this.smsStatusTimer();
      $("#facebook_call_timer").css({ "display": "none" });
      $("#whatsapp_call_timer").css({ "display": "none" });
      $("#twitter_call_timer").css({ "display": "none" });
    } else if (data === 'Facebook') {
      $("#agentCallStatus").text('Facebook');
      $("#sms_call_timer").css({ "display": "none" });
      $("#chat_call_timer").css({ "display": "none" });
      $("#email_call_timer").css({ "display": "none" });
      this.facebookResetTimer();
      this.facebookStatusTimer();
      $("#facebook_call_timer").css({ "display": "block" });
      $("#whatsapp_call_timer").css({ "display": "none" });
      $("#twitter_call_timer").css({ "display": "none" });
    }
    else if (data === 'Whatsapp') {
      $("#agentCallStatus").text('Whatsapp');
      $("#sms_call_timer").css({ "display": "none" });
      $("#chat_call_timer").css({ "display": "none" });
      $("#email_call_timer").css({ "display": "none" });
      this.whatsappResetTimer();
      this.whatsappStatusTimer();
      $("#facebook_call_timer").css({ "display": "none" });
      $("#whatsapp_call_timer").css({ "display": "block" });
      $("#twitter_call_timer").css({ "display": "none" });
    } else if (data === 'Twitter') {
      $("#agentCallStatus").text('Twitter');
      $("#sms_call_timer").css({ "display": "none" });
      $("#chat_call_timer").css({ "display": "none" });
      $("#email_call_timer").css({ "display": "none" });
      this.twitterResetTimer();
      this.twitterStatusTimer();
      $("#facebook_call_timer").css({ "display": "none" });
      $("#whatsapp_call_timer").css({ "display": "none" });
      $("#twitter_call_timer").css({ "display": "block" });
    } else {
      $("#sms_call_timer").css({ "display": "none" });
      $("#chat_call_timer").css({ "display": "none" });
      $("#email_call_timer").css({ "display": "none" });
      $("#facebook_call_timer").css({ "display": "none" });
      $("#whatsapp_call_timer").css({ "display": "none" });
      $("#twitter_call_timer").css({ "display": "none" });
    }

    this.show_pause_call = false;
    this.show_Resume_call = true;
  }
  SelectResumeClick() {
    $("#break_call_timer").css({ "display": "none" });
    sessionStorage.removeItem('media_status')
    this.chatReadyResetTimer();
    this.smsResetTimer();
    this.emailResetTimer();
    this.whatsappResetTimer();
    this.facebookResetTimer();
    this.twitterResetTimer();
    this.waitingResetTimer();
    this.getAgentReady();
    $("#sms_call_timer").css({ "display": "none" });
    $("#chat_call_timer").css({ "display": "none" });
    $("#email_call_timer").css({ "display": "none" });
    $("#facebook_call_timer").css({ "display": "none" });
    $("#whatsapp_call_timer").css({ "display": "none" });
    $("#twitter_call_timer").css({ "display": "none" });
    this.show_pause_call = true;
    this.show_Resume_call = false;

    if (sessionStorage.getItem("camp_type") === "INBOUND") {
      $("#agentCallStatus").css({ "visibility": "visible" });
      $("#agentCallStatus").text('Waiting');
      $("#waiting_call_timer").css({ "display": "block" });
      $("#ready_call_timer").css({ "display": "none" });
      $("#agent_is_registered").css({ "background": "#ff4961" });
      this.waitingStatusTimer();
    } else if (sessionStorage.getItem("camp_type") === "OUTBOUND") {
      $("#agentCallStatus").css({ "visibility": "visible" });
      $("#agentCallStatus").text('Ready');
      $("#waiting_call_timer").css({ "display": "none" });
      $("#ready_call_timer").css({ "display": "block" });
      this.readyStatusTimer();
    } else {
      $("#agentCallStatus").css({ "visibility": "visible" });
      $("#agentCallStatus").text('');
      var getOption = <HTMLInputElement>document.getElementById('status_timer_data')
      getOption.innerText = "00:00:00"
    }
  }

  //  Chat time counter 
  chatStatusTimer() {
    this.chatInterval = setInterval(() => {
      if (this.chattime === 0) {
        this.chattime++;
      } else {
        this.chattime++;
      }
      var getOption = <HTMLInputElement>document.getElementById('chatTimerHead')
      console.log(getOption)
      getOption.innerHTML = this.transform(this.chattime)
    }, 1000);
  }
  chatReadyResetTimer() {
    clearInterval(this.chatInterval);
    this.chatInterval = setTimeout(() => {
      this.chattime = 0;
    }, 0);
  }
  //  Email time counter 
  emailStatusTimer() {
    this.emailInterval = setInterval(() => {
      if (this.emailtime === 0) {
        this.emailtime++;
      } else {
        this.emailtime++;
      }
      var getOption = <HTMLInputElement>document.getElementById('emailTimerHead')
      getOption.innerHTML = this.transform(this.emailtime)

    }, 1000);
  }
  emailResetTimer() {
    clearInterval(this.emailInterval);
    this.emailInterval = setTimeout(() => {
      this.emailtime = 0;
    }, 0);
  }

  //  sms time counter 
  smsStatusTimer() {
    this.smsInterval = setInterval(() => {
      if (this.smstime === 0) {
        this.smstime++;
      } else {
        this.smstime++;
      }
      var getOption = <HTMLInputElement>document.getElementById('smsTimerHead')
      getOption.innerHTML = this.transform(this.smstime)

    }, 1000);
  }
  smsResetTimer() {
    clearInterval(this.smsInterval);
    this.smsInterval = setTimeout(() => {
      this.smstime = 0;
    }, 0);
  }

  //  whatsapp time counter 
  whatsappStatusTimer() {
    this.whatsappInterval = setInterval(() => {
      if (this.whatsapptime === 0) {
        this.whatsapptime++;
      } else {
        this.whatsapptime++;
      }
      var getOption = <HTMLInputElement>document.getElementById('whatsappTimerHead')
      getOption.innerHTML = this.transform(this.whatsapptime)

    }, 1000);
  }
  whatsappResetTimer() {
    clearInterval(this.whatsappInterval);
    this.whatsappInterval = setTimeout(() => {
      this.whatsapptime = 0;
    }, 0);
  }
  //  facebook time counter 
  facebookStatusTimer() {
    this.facebookInterval = setInterval(() => {
      if (this.facebooktime === 0) {
        this.facebooktime++;
      } else {
        this.facebooktime++;
      }
      var getOption = <HTMLInputElement>document.getElementById('facebookTimerHead')
      getOption.innerHTML = this.transform(this.facebooktime)

    }, 1000);
  }
  facebookResetTimer() {
    clearInterval(this.facebookInterval);
    this.facebookInterval = setTimeout(() => {
      this.facebooktime = 0;
    }, 0);
  }
  //  Twitter time counter 
  twitterStatusTimer() {
    this.twitterInterval = setInterval(() => {
      if (this.twittertime === 0) {
        this.twittertime++;
      } else {
        this.twittertime++;
      }
      var getOption = <HTMLInputElement>document.getElementById('twitterTimerHead')
      getOption.innerHTML = this.transform(this.twittertime)

    }, 1000);
  }
  twitterResetTimer() {
    clearInterval(this.twitterInterval);
    this.twitterInterval = setTimeout(() => {
      this.twittertime = 0;
    }, 0);
  }
  // start Ready time counter 
  readyStatusTimer() {
    this.readyInterval = setInterval(() => {
      if (this.Readytime === 0) {
        this.Readytime++;
      } else {
        this.Readytime++;
      }
      // this.display = this.transform(this.Readytime)
      var getOption = <HTMLInputElement>document.getElementById('readyTimerHead')
      getOption.innerHTML = this.transform(this.Readytime)
    }, 1000);
  }
  ReadyResetTimer() {
    clearInterval(this.readyInterval);
    this.readyInterval = setTimeout(() => {
      this.Readytime = 0;
    }, 0);
  }
  // start waiting time counter 
  waitingStatusTimer() {
    this.waitingInterval = setInterval(() => {
      if (this.waitingtime === 0) {
        this.waitingtime++;
      } else {
        this.waitingtime++;
      }
      // this.display = this.transform(this.waitingtime)
      var getOption = <HTMLInputElement>document.getElementById('waitingTimerHead')
      getOption.innerHTML = this.transform(this.waitingtime)

    }, 1000);
  }
  waitingResetTimer() {
    clearInterval(this.waitingInterval);
    this.waitingInterval = setTimeout(() => {
      this.waitingtime = 0;
    }, 0);
  }

  // start Break time counter 
  BreakStatusTimer() {
    this.BreakInterval = setInterval(() => {
      if (this.Breaktime === 0) {
        this.Breaktime++;
      } else {
        this.Breaktime++;
      }
      // this.display = this.transform(this.waitingtime)
      var getOption = <HTMLInputElement>document.getElementById('breakHead')
      getOption.innerHTML = this.transform(this.Breaktime)
    }, 1000);
  }
  BreakResetTimer() {
    console.log()
    clearInterval(this.BreakInterval);
    this.BreakInterval = setTimeout(() => {
      this.Breaktime = 0;
    }, 0);
  }

  // Available timer
  startStatusTimer() {
    this.interval = setInterval(() => {
      if (this.time === 0) {
        this.time++;
      } else {
        this.time++;
      }
      this.display = this.transform(this.time)
    }, 1000);
  }
  resetTimer() {
    clearInterval(this.interval);
    this.interval = setTimeout(() => {
      this.time = 0;
    }, 0);
  }
  transform(value: number): string {
    var sec_num = value;
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    return ('00' + hours).slice(-2) + ':' + ('00' + minutes).slice(-2) + ':' + ('00' + seconds).slice(-2);
  }



  getAvailableCampaign() {
    // registration and avaialable camapign 
    this.dataOfUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.dataOfUser.account_type == 'Agent' || this.dataOfUser.account_type == 'Supervisor') {
      this.regesterAgentOnShip();
      const agent_data = {
        agent_uuid: this.dataOfUser.agent_domain_uuid,
      };
      // this.apiService.checkAgentRequestedBreak(agent_data).subscribe((response) => {
      //   if (response['BreakRequestStatus'] === "approved") {
      //   }
      //   if (response['BreakRequestStatus'] === "waiting" && response['AgentCurrentStatus'] === "Available") {
      //     this.currentBreak = response['BreakRequestStatus'];
      //     console.log("first check")
      //   } else if (response['BreakRequestStatus'] === "approved" && response['AgentCurrentStatus'] != "Logged Out") {
      //     // this.router.navigateByUrl('sessions/lock');
      //   } else if (response['BreakRequestStatus'] === "approved" && response['AgentCurrentStatus'] === "Available") {
      //     this.currentBreak = response['AgentCurrentStatus'];
      //     console.log("first check")

      //   } else if (response['BreakRequestStatus'] === "disapproved" && response['AgentCurrentStatus'] === "Available") {
      //     this.currentBreak = response['AgentCurrentStatus'];
      //     console.log("first check")

      //   } else if (response['Status'] == 2) {
      //     this.currentBreak = this.dataOfUser.agent_status;
      //   }
      // });
    }
  }

  manageBreakOptions() {
    this.showBreakOption = false;
    switch (this.dataOfUser.account_type) {
      case 'Agent':
        this.showSettingIcon = false;
        this.showBreakOption = true;
        this.showCampaignsOptions = true;
        this.show_pause_call = true;
        this.getCampaignList();
        break;
      case 'Supervisor':
        this.showSettingIcon = false;
        this.showBreakOption = true;
        this.showCampaignsOptions = true;
        this.show_pause_call = true;
        this.getCampaignList();
        break;
      case 'Tenant':
        this.showSettingIcon = true;
    }

    const requestDta = {
      domain_uuid: this.dataOfUser.domain_uuid
    }
    // this.apiService.getBreakList(requestDta).subscribe((response) => {
    //   if (response['BreakList'] === undefined) {
    //     this.noDataFound = true;
    //   } else {
    //     this.noDataFound = false;
    //     this.allBreaksData = response['BreakList'];
    //     for (let i = 0; i <= this.allBreaksData.length; i++) {

    //     }
    //   }
    // });
  }

  // new code start 
  regesterAgentOnShip() {
    // console.log('hello + regesterAgentOnShip');
    const domainPart1 = 'wss://';
    const domainPart2 = 'telephony.telemo.io';
    const domainPart3 = ':7443';
    const domain = domainPart1.concat(domainPart2, domainPart3);
    const telemoSdk = new Telemo();
    const options = {
      username: this.dataOfUser.extension,
      password: this.dataOfUser.extension_password,
      display_name: this.dataOfUser.extension,
      trace_sip: 'true',
      turnServerURL: 'turn:numb.viagenie.ca',
      turnUsername: 'hariom@startelelogic.com',
      turnPassword: 'q1w2e3r4',
      subDomain: this.dataOfUser.domain_name
    };
    telemoSdk.login(options, domain);
    setTimeout(() => {
      this.getAgentReadyToCall();
      // alert(sessionStorage.getItem('isResistered'))
      // if(sessionStorage.getItem('isResistered') === 'true'){
      // }

    }, 1000);
  }
  getAgentReady() {
    const domainPart1 = 'wss://';
    const domainPart2 = 'telephony.telemo.io';
    const domainPart3 = ':7443';
    const domain = domainPart1.concat(domainPart2, domainPart3);
    const telemoSdk = new Telemo();
    const options = {
      username: this.dataOfUser.extension,
      password: this.dataOfUser.extension_password,
      display_name: this.dataOfUser.extension,
      trace_sip: 'true',
      turnServerURL: 'turn:numb.viagenie.ca',
      turnUsername: 'hariom@startelelogic.com',
      turnPassword: 'q1w2e3r4',
      subDomain: this.dataOfUser.domain_name
    };
    telemoSdk.login(options, domain);
  }
  getAgentReadyToCall() {
    if (sessionStorage.getItem('isResistered') === 'true') {
      const params = {
        agent_uuid: this.dataOfUser.agent_domain_uuid,
        break_name: 'Available',
        domain_uuid: this.dataOfUser.domain_uuid
      };
      // this.apiService.requestBreak(params).subscribe((response) => {
      //   if (response['Status'] === String(1)) {
      //     this.ReadyResetTimer();
      //     this.waitingResetTimer();
      //     this.isAgentReady = true;
      //     const element = document.getElementById('customizer');
      //     const elementdata = document.getElementById('agent_is_registered');
      //     $(".agent_registered").show();
      //     $(".agent_unregistered").hide();
      //     $("#agent_is_registered").css("background", "#92c560");
      //     this.toastr.success('Agent Ready', 'Success!');
      //     if (sessionStorage.getItem("camp_type") === "INBOUND") {
      //       $("#agentCallStatus").css({ "visibility": "visible" });
      //       $("#agentCallStatus").text('Waiting')
      //       $("#agent_is_registered").css({ "background": "#ff4961" });
      //       $("#acwTime").css({ "display": "none" });
      //       $("#ringing_call_timer").css({ "display": "none" });
      //       $("#ringing_call_timer_Full").css({ "display": "none" });
      //       $("#outgoingResetTimer").css({ "display": "none" });
      //       $("#status_timer").css({ "display": "none" });
      //       $("#ready_call_timer").css({ "display": "none" });
      //       $("#waiting_call_timer").css({ "display": "block" });
      //       $("#break_call_timer").css({ "display": "none" });
      //       $("#sms_call_timer").css({ "display": "none" });
      //       $("#chat_call_timer").css({ "display": "none" });
      //       $("#email_call_timer").css({ "display": "none" });
      //       $("#facebook_call_timer").css({ "display": "none" });
      //       $("#whatsapp_call_timer").css({ "display": "none" });
      //       $("#twitter_call_timer").css({ "display": "none" });
      //       this.waitingStatusTimer();
      //     } else {
      //       $("#agentCallStatus").css({ "visibility": "visible" });
      //       $("#agentCallStatus").text('Ready')
      //       $("#acwTime").css({ "display": "none" });
      //       $("#ready_call_timer").css({ "display": "block" });
      //       $("#ringing_call_timer").css({ "display": "none" });
      //       $("#outgoingResetTimer").css({ "display": "none" });
      //       $("#status_timer").css({ "display": "none" });
      //       $("#waiting_call_timer").css({ "display": "none" });
      //       $("#break_call_timer").css({ "display": "none" });
      //       $("#sms_call_timer").css({ "display": "none" });
      //       $("#chat_call_timer").css({ "display": "none" });
      //       $("#email_call_timer").css({ "display": "none" });
      //       $("#facebook_call_timer").css({ "display": "none" });
      //       $("#whatsapp_call_timer").css({ "display": "none" });
      //       $("#twitter_call_timer").css({ "display": "none" });
      //       this.readyStatusTimer();
      //     }
      //   } else {
      //     this.isAgentReady = false;
      //     $(".agent_registered").hide();
      //     $(".agent_unregistered").show();
      //     $("#agent_is_registered").css("background", "#f10c0c");
      //     this.toastr.error('Agent Not Ready', 'Error!');
      //   }
      // });
    }
  }

  getCampaignList() {
    const params = {
      domain_uuid: this.dataOfUser.domain_uuid,
      agent_uuid: this.dataOfUser.agent_domain_uuid
    }
    // this.apiService.getAgentWithCampaign(params).subscribe((response) => {
    //   if (response['Status'] === String(1)) {
    //     this.allCampaignsData = response['OutboundCamps'];
    //     if (this.allCampaignsData.length === 0) {
    //       this.no_camp_list_showing = true;
    //       this.updateButtonShow = false;

    //       this.camp_list_showing = false;
    //       var getOption = <HTMLInputElement>document.getElementById('no_camp_test')
    //       getOption.classList.remove('camp_head')

    //     } else {
    //       this.no_camp_list_showing = false;
    //       this.camp_list_showing = true;
    //       this.updateButtonShow = true;
    //       var getOption = <HTMLInputElement>document.getElementById('no_camp_test')
    //       getOption.classList.add('camp_head')
    //     }
    //     this.getAgentCampain();
    //   } else {
    //     this.allCampaignsData = '';
    //   }
    // });
    this.soicalQueueData = '';

  }

  avialbleSt(data) {
    this.BreakResetTimer();
    this.ReadyResetTimer();
    this.waitingResetTimer();
    if (data === 'Available') {
      if (sessionStorage.getItem("camp_type") === "INBOUND") {
        $("#agentCallStatus").css({ "visibility": "visible" });
        $("#ready_call_timer").css({ "display": "none" });
        $("#ringing_call_timer").css({ "display": "none" });
        $("#outgoingResetTimer").css({ "display": "none" });
        $("#status_timer").css({ "display": "none" });
        $("#waiting_call_timer").css({ "display": "block" });
        $("#status_timer").css({ "display": "none" });
        $("#agent_is_registered").css({ "background": "#ff4961" });
        $("#acwTime").css({ "display": "none" });
        $("#agentCallStatus").text('Waiting');
        $("#break_call_timer").css({ "display": "none" });
        $("#sms_call_timer").css({ "display": "none" });
        $("#chat_call_timer").css({ "display": "none" });
        $("#email_call_timer").css({ "display": "none" });
        $("#facebook_call_timer").css({ "display": "none" });
        $("#whatsapp_call_timer").css({ "display": "none" });
        $("#twitter_call_timer").css({ "display": "none" });
        $("#agent_is_registered").css({ "background": "#f24c61" });
        this.waitingStatusTimer();
      } else {
        $("#agent_is_registered").css({ "background": "#92c560" });
        $("#agentCallStatus").css({ "visibility": "visible" });
        $("#agentCallStatus").text('Ready');
        $("#acwTime").css({ "display": "none" });
        $("#ready_call_timer").css({ "display": "block" });
        $("#ringing_call_timer").css({ "display": "none" });
        $("#outgoingResetTimer").css({ "display": "none" });
        $("#status_timer").css({ "display": "none" });
        $("#waiting_call_timer").css({ "display": "none" });
        $("#break_call_timer").css({ "display": "none" });
        $("#sms_call_timer").css({ "display": "none" });
        $("#chat_call_timer").css({ "display": "none" });
        $("#email_call_timer").css({ "display": "none" });
        $("#facebook_call_timer").css({ "display": "none" });
        $("#whatsapp_call_timer").css({ "display": "none" });
        $("#twitter_call_timer").css({ "display": "none" });
        this.readyStatusTimer();
      }

    }
    const domainPart1 = 'wss://';
    const domainPart2 = 'telephony.telemo.io';
    const domainPart3 = ':7443';
    const domain = domainPart1.concat(domainPart2, domainPart3);
    const telemoSdk = new Telemo();
    const options = {
      username: this.dataOfUser.extension,
      password: this.dataOfUser.extension_password,
      display_name: this.dataOfUser.extension,
      trace_sip: 'true',
      turnServerURL: 'turn:numb.viagenie.ca',
      turnUsername: 'hariom@startelelogic.com',
      turnPassword: 'q1w2e3r4',
      subDomain: this.dataOfUser.domain_name
    };
    telemoSdk.login(options, domain);
    setTimeout(() => {
      var getOption = <HTMLInputElement>document.getElementById('avialability_check')
      getOption.innerText = data;
      getOption.style.color = "#92c560";
    }, 1000)

    const params = {
      agent_uuid: this.dataOfUser.agent_domain_uuid,
      break_name: 'Available',
      domain_uuid: this.dataOfUser.domain_uuid
    };
    // this.apiService.requestBreak(params).subscribe((response) => {
    //   if (response['Status'] === String(1)) {
    //   } else {
    //   }
    // });
    this.resetTimer();
    this.startStatusTimer();
  }
  applyBreak(appliedBreak) {
    console.log(appliedBreak)
    let break_data = appliedBreak
    this.stopBreakApiInterval();
    const requestDta = {
      agent_uuid: this.dataOfUser.agent_domain_uuid,
      break_name: appliedBreak,
      domain_uuid: this.dataOfUser.domain_uuid
    }
    // this.apiService.requestBreak(requestDta).subscribe((response) => {
    //   if (response['Status'] == 1) {
    //     this.toastr.success(response['Msg'], 'Success!');

    //     this.stopCheckingBreak = setInterval(() => {
    //       const requestDta = {
    //         agent_uuid: this.dataOfUser.agent_domain_uuid,
    //       }
    //       this.apiService.checkAgentRequestedBreak(requestDta).subscribe((response) => {

    //         if (response['BreakRequestStatus'] === "approved" && response['AgentCurrentStatus'] != "Available") {
    //           this.stopBreakApiInterval();
    //           this.toastr.success("Your requested break is approved by supervisor", 'Success!');
    //           var getOption = <HTMLInputElement>document.getElementById('avialability_check')
    //           getOption.innerText = appliedBreak;
    //           sessionStorage.setItem("set_value", appliedBreak)
    //           getOption.style.color = "red";
    //           this.resetTimer();
    //           this.startStatusTimer();
    //           const telemoSdk = new Telemo();
    //           telemoSdk.unRegisterUser();
    //           this.ReadyResetTimer();
    //           this.waitingResetTimer();
    //           // $("#agentCallStatus").css({ "visibility": "visible" });
    //           this.BreakResetTimer();
    //           this.BreakStatusTimer();
    //           $("#agent_is_registered").css({ "background": "#ff9149" });
    //           if (sessionStorage.getItem("camp_type") === "INBOUND") {
    //             $("#agentCallStatus").css({ "visibility": "visible" });
    //             $("#ready_call_timer").css({ "display": "none" });
    //             $("#waiting_call_timer").css({ "display": "none" });
    //             $("#agentCallStatus").text('Break')
    //             $("#acwTime").css({ "display": "none" });
    //             $("#break_call_timer").css({ "display": "block" });
    //             $("#sms_call_timer").css({ "display": "none" });
    //             $("#chat_call_timer").css({ "display": "none" });
    //             $("#email_call_timer").css({ "display": "none" });
    //             $("#facebook_call_timer").css({ "display": "none" });
    //             $("#whatsapp_call_timer").css({ "display": "none" });
    //             $("#twitter_call_timer").css({ "display": "none" });
    //           } else {
    //             $("#agentCallStatus").css({ "visibility": "visible" });
    //             $("#agentCallStatus").text('Ready')
    //             $("#ready_call_timer").css({ "display": "none" });
    //             $("#break_call_timer").css({ "display": "block" });
    //             $("#waiting_call_timer").css({ "display": "none" });
    //             $("#acwTime").css({ "display": "none" });
    //             $("#sms_call_timer").css({ "display": "none" });
    //             $("#chat_call_timer").css({ "display": "none" });
    //             $("#email_call_timer").css({ "display": "none" });
    //             $("#facebook_call_timer").css({ "display": "none" });
    //             $("#whatsapp_call_timer").css({ "display": "none" });
    //             $("#twitter_call_timer").css({ "display": "none" });
    //             $("#agentCallStatus").text('Break')
    //           }
    //           // this.regesterAgentOnShip();
    //           // console.log(getOption)
    //           if (sessionStorage.getItem('camp_uuid_for_call_tab') === 'undefined' || sessionStorage.getItem('camp_uuid_for_call_tab') === null) {
    //             this.router.navigateByUrl('sessions/lock');
    //           }
    //         } else if (response['BreakRequestStatus'] === "disapproved") {
    //           this.currentBreak = "Available";
    //           this.stopBreakApiInterval();
    //           this.toastr.error("Break deny by your supervisor", 'Error!');
    //         }
    //       });
    //     }, 5000);
    //   } else {
    //     this.toastr.error(response['Msg'], 'Error!');
    //   }
    // });
  }

  stopBreakApiInterval() {
    clearInterval(this.stopCheckingBreak);
  }

  getOutboundCampaign(get_outbound_camp_uuid) {
    this.set_outbound_camp_uuid = get_outbound_camp_uuid;
    // alert(this.set_outbound_camp_uuid);
  }

  public updateOptions(data): void {

    // load call tab
    if (this.set_outbound_camp_uuid !== undefined) {
      sessionStorage.setItem('get_outbound_camp_uuid', this.set_outbound_camp_uuid);
      const params = {
        "domain_uuid": this.dataOfUser.domain_uuid,
        "camp_uuid": this.set_outbound_camp_uuid
      }
      // this.apiService.getTrunkPrefix(params).subscribe((response) => {
      //   sessionStorage.setItem("camp_type", response['Campaign']['camp_type'])
      //   sessionStorage.setItem("wraup_up_time", response['Campaign']['camp_wrap_up_time'])
      //   if (response['Status'] === String(1)) {
      //     sessionStorage.setItem('get_outbound_siptrunk_siptrunk_uuid', response['Campaign']['siptrunk_uuid']);
      //   } else {
      //     sessionStorage.setItem('get_outbound_siptrunk_siptrunk_uuid', '');
      //   }
      // });
    }
    console.log(this.selectedOutbound)
    if (this.selectedOutbound === 0) {
      sessionStorage.setItem('get_outbound_camp_uuid', undefined);
      this.sessionCheck = sessionStorage.setItem('get_outbound_camp_uuid', undefined);
    }
    let camp_true = '';
    let camp_false = '';
    this.allCampaignsData.forEach((camp: {
      checked: boolean,
      CampUUID: string;
    }) => {
      if (camp.checked) {
        camp_true = (camp_true) ? `${camp_true},${camp.CampUUID}` : camp.CampUUID;
      } else {
        camp_false = (camp_false) ? `${camp_false},${camp.CampUUID}` : camp.CampUUID;
      }
    });
    this.camp_data_check = this.allCampaignsData

    const payload: any = {
      domain_uuid: this.dataOfUser.domain_uuid,
      agent_uuid: this.dataOfUser.agent_domain_uuid,
      camp_true,
      camp_false
    };

    if (this.selectedOutbound === 0 && this.selectedInbound === 0) {
      this.toastr.error('Please select any campaign from campaign list', 'Error!');
    } else if (sessionStorage.getItem('wrapup_counter') > "0") {
      this.toastr.error('Your wrapup time is not finished yet,wait until it completes', 'Error!');
    }
    else {
      // this.apiService.putAgent('updateAgentCampaignTier', payload).subscribe((res: any) => {
      //   if (Number(res.Status) === 1) {
      //     // get queue which is mapped with outbound campaign    
      //     this.apiService.getQueueMapWithCampaign(this.dataOfUser.domain_uuid, sessionStorage.getItem('get_outbound_camp_uuid'), this.dataOfUser.agent_domain_uuid).subscribe((response) => {
      //       if (response['Status'] === String(1)) {
      //         sessionStorage.setItem('outbound_campaign_queue_uuid', response['QueueUUID'][0]);
      //       }
      //     });
      //     // get queue uuid ends here 
      //     if (this.dataOfUser.account_type === 'Agent' || this.dataOfUser.account_type === 'Supervisor') {
      //       setTimeout(() => {
      //         if (sessionStorage.getItem('isResistered') === 'true') {
      //           this.getAgentReadyToCall();
      //           this.isAgentReady = true;
      //         } else if (sessionStorage.getItem('isResistered') === 'false') {
      //           this.isAgentReady = false;
      //           this.regesterAgentOnShip();
      //         } else if (sessionStorage.getItem('isResistered') === null) {
      //           this.isAgentReady = false;
      //           this.regesterAgentOnShip();
      //         }
      //       }, 2000);
      //     }
      //   } else {
      //     this.toastr.error(res['Msg'], 'Error!');
      //   }
      // });
    }
  }

  getAgentCampain(): void {
  }

  updateCount(data) {
    console.log(data)
    setTimeout(() => {
      this.selectedInbound = 0;
      this.selectedOutbound = 0;
      this.selectedBlended = 0;
      this.allCampaignsData.forEach((camp: {
        CampUUID: string;
        CampType: string;
        checked: boolean;
      }) => {
        // alert('camp.checked'+camp.checked)
        if (camp.checked) {
          if (String(camp.CampType).toLocaleLowerCase() === String('INBOUND').toLocaleLowerCase()) {
            this.selectedInbound += 1;
          } else if (String(camp.CampType).toLocaleLowerCase() === String('BLENDED').toLocaleLowerCase()) {
            this.selectedBlended += 1;
          } else {
            this.selectedOutbound += 1;
          }
        }
      });
    }, 500);
  }

  signout() {
    if (this.dataOfUser.account_type == 'Agent' || this.dataOfUser.account_type == 'Supervisor') {
      const requestDta = {
        agent_uuid: this.dataOfUser.agent_domain_uuid,
        break_name: "Logged Out",
        domain_uuid: this.dataOfUser.domain_uuid
      }
      // this.apiService.requestBreak(requestDta).subscribe((response) => {
      //   if (response['Status'] == 1) {
      //     // const telemoSdk = new Telemo();
      //     // telemoSdk.unRegisterUser();
      //     this.authService.doLogout();
      //     sessionStorage.removeItem('wraup_up_time')
      //     sessionStorage.removeItem('wrapup_counter')
      //     this.toastr.success(response['Msg'], 'Success!');

      //   } else {
      //     this.toastr.error(response['Msg'], 'Error!');
      //   }
      // });
    } else if (this.dataOfUser.account_type == 'Tenant' || this.dataOfUser.account_type == 'Superuser') {
      this.authService.doLogout();
    }
  }

  showBackToSuperUserLogin() {
    if (JSON.parse(localStorage.getItem('currentUser')) !== null && JSON.parse(localStorage.getItem('currentUser')) !== null && sessionStorage.getItem('show_superuser_login_icon') === 'true') {
      this.showBackToSuperUserLoginIcon = true;
    }
  }

  getAvatar() {
    return (this.dataOfUser.agent_name || this.dataOfUser.domain_name).charAt(0);
  }

  backToSuperadmin() {
    localStorage.removeItem('currentLayoutStyle');
    sessionStorage.removeItem('show_superuser_login_icon');
    this.setUserInStorage(JSON.parse(localStorage.getItem('superuser_user')));
    let returnUrl = '/dashboard/';
    window.location.href = '/dashboard';
    if (this.returnUrl) {
      returnUrl = this.returnUrl;
    }
    this.router.navigate([returnUrl]);
  }

  setUserInStorage(res) {
    localStorage.setItem('currentUser', JSON.stringify(res));
  }

  ngAfterViewInit(): void {
    this.refreshView();
  }

  refreshView() {
    const iconElement = document.getElementsByClassName('toggle-icon');
    const menuColorElement = document.getElementsByClassName('main-menu');
    const navigationElement = document.getElementsByClassName('main-menu');
    const navbarElement = document.getElementsByClassName('header-navbar');
    const themeColorElement = document.getElementsByClassName('header-navbar');
    const element = document.getElementsByClassName('navbar-header');
    const boxelement = document.getElementById('customizer');
    if (iconElement) {
      if (this._themeSettingsConfig.colorTheme === 'semi-light' || this._themeSettingsConfig.colorTheme === 'light') {
        this._renderer.removeClass(iconElement.item(0), 'white');
        this._renderer.addClass(iconElement.item(0), 'blue-grey');
        this._renderer.addClass(iconElement.item(0), 'darken-3');
      } else if (this._themeSettingsConfig.colorTheme === 'semi-dark' || this._themeSettingsConfig.colorTheme === 'dark') {
        this._renderer.addClass(iconElement.item(0), 'white');
        this._renderer.removeClass(iconElement.item(0), 'blue-grey');
        this._renderer.removeClass(iconElement.item(0), 'darken-3');
      }
    }

    if (this._themeSettingsConfig.colorTheme === 'semi-light') {
      this.selectedHeaderNavBarClass = this._themeSettingsConfig.color;
      this.selectedNavBarHeaderClass = '';
    } else if (this._themeSettingsConfig.colorTheme === 'semi-dark') {
      this.selectedNavBarHeaderClass = this._themeSettingsConfig.color;
      this.selectedHeaderNavBarClass = '';
    } else if (this._themeSettingsConfig.colorTheme === 'dark') {
      this.selectedHeaderNavBarClass = this._themeSettingsConfig.color;
      this.selectedNavBarHeaderClass = '';
    } else if (this._themeSettingsConfig.colorTheme === 'light') {
      this.selectedHeaderNavBarClass = this._themeSettingsConfig.color;
      this.selectedNavBarHeaderClass = this._themeSettingsConfig.color;
    }
    if (menuColorElement) {
      if (this._themeSettingsConfig.menuColor === 'menu-dark') {
        this._renderer.removeClass(menuColorElement.item(0), 'menu-light');
        this._renderer.addClass(menuColorElement.item(0), 'menu-dark');
      } else if (this._themeSettingsConfig.menuColor === 'menu-light') {
        this._renderer.removeClass(menuColorElement.item(0), 'menu-dark');
        this._renderer.addClass(menuColorElement.item(0), 'menu-light');
      }
    }

    if (themeColorElement) {
      if (this._themeSettingsConfig.colorTheme === 'semi-light') {
        this._renderer.removeClass(themeColorElement.item(0), 'navbar-semi-dark');
        this._renderer.removeClass(themeColorElement.item(0), 'navbar-dark');
        this._renderer.removeClass(themeColorElement.item(0), 'navbar-light');
      } else if (this._themeSettingsConfig.colorTheme === 'semi-dark') {
        this._renderer.removeClass(themeColorElement.item(0), 'navbar-semi-light');
        this._renderer.removeClass(themeColorElement.item(0), 'navbar-dark');
        this._renderer.removeClass(themeColorElement.item(0), 'navbar-light');
      } else if (this._themeSettingsConfig.colorTheme === 'dark') {
        this._renderer.removeClass(themeColorElement.item(0), 'navbar-semi-dark');
        this._renderer.removeClass(themeColorElement.item(0), 'navbar-semi-dark');
        this._renderer.removeClass(themeColorElement.item(0), 'navbar-light');
      } else if (this._themeSettingsConfig.colorTheme === 'light') {
        this._renderer.removeClass(themeColorElement.item(0), 'navbar-semi-dark');
        this._renderer.removeClass(themeColorElement.item(0), 'navbar-dark');
        this._renderer.removeClass(themeColorElement.item(0), 'navbar-semi-light');
      }
    }

    if (navigationElement) {
      if (this._themeSettingsConfig.navigation === 'menu-native-scroll') {
        this._renderer.addClass(navigationElement.item(0), 'menu-native-scroll');
      } else if (this._themeSettingsConfig.navigation === 'menu-icon-right') {
        this._renderer.addClass(navigationElement.item(0), 'menu-icon-right');
      } else if (this._themeSettingsConfig.navigation === 'menu-bordered') {
        this._renderer.addClass(navigationElement.item(0), 'menu-bordered');
      } else if (this._themeSettingsConfig.navigation === 'menu-flipped') {
        this._renderer.addClass(document.body, 'menu-flipped');
      } else if (this._themeSettingsConfig.navigation === 'menu-collapsible') {
        this._renderer.addClass(navigationElement.item(0), 'menu-collapsible');
      } else if (this._themeSettingsConfig.navigation === 'menu-static') {
        this._renderer.addClass(navigationElement.item(0), 'menu-static');
      }
    }

    if (navbarElement) {
      if (this._themeSettingsConfig.menu === 'navbar-static-top') {
        this._renderer.addClass(navbarElement.item(0), 'navbar-static-top');
        this._renderer.addClass(navigationElement.item(0), 'menu-static');
      }
    }

    if (navbarElement) {
      if (this._themeSettingsConfig.menu === 'semi-light') {
        this._renderer.addClass(navbarElement.item(0), 'navbar-semi-light bg-gradient-x-grey-blue');
      } else if (this._themeSettingsConfig.menu === 'semi-dark') {
        this._renderer.addClass(navbarElement.item(0), 'navbar-semi-dark');
      } else if (this._themeSettingsConfig.menu === 'dark') {
        this._renderer.addClass(navbarElement.item(0), 'navbar-dark');
      } else if (this._themeSettingsConfig.menu === 'light') {
        this._renderer.addClass(navbarElement.item(0), 'navbar-light');
      }
    }
  }

  resetOpenMenu() {
    for (let i = 0; i < this._menuSettingsConfig.vertical_menu.items.length; i++) {
      const menu = this._menuSettingsConfig.vertical_menu.items[i];
      if (!menu.submenu) {
        menu['isOpen'] = false;
        menu['isActive'] = false;
        menu['hover'] = false;
      } else if (menu.submenu) {
        for (let j = 0; j < menu.submenu.items.length; j++) {
          menu['isOpen'] = false;
          menu['isActive'] = false;
          menu['hover'] = false;
          menu.submenu.items[j]['isOpen'] = false;
        }
      }
    }
  }

  setOpenInNavbar(value) {
    for (let i = 0; i < this._menuSettingsConfig.vertical_menu.items.length; i++) {
      const menu = this._menuSettingsConfig.vertical_menu.items[i];
      if (!menu.submenu &&
        menu.page === this.router.url) {
        menu['isOpen'] = value;
        menu['isActive'] = value;
      } else if (menu.submenu) {
        for (let j = 0; j < menu.submenu.items.length; j++) {
          if (menu.submenu.items[j].page === this.router.url) {
            menu['isOpen'] = value;
            menu['isActive'] = value;
            menu.submenu.items[j]['isOpen'] = value;
            menu.submenu.items[j]['isActive'] = value;
            break;
          }
        }
      }
    }
  }

  /**
   * Use for fixed left aside menu, to show menu on mouseenter event.
   * @param e Event
   */
  mouseEnter(e) {
    if (this.navbarService.isFixedMenu()) {
      return;
    }
    this.navbarService.setMouseInRegion(true);
    const navBar = this.document.getElementById('navbar-header');
    const mainMenu = this.document.getElementById('main-menu');

    // check if the left aside menu is fixed
    if (!navBar.classList.contains('expanded')) {
      this._renderer.addClass(navBar, 'expanded');
      this._renderer.addClass(mainMenu, 'expanded');
      this.resetOpenMenu();
      this.setOpenInNavbar(true);
    }
  }

  /**
   * Use for fixed left aside menu, to show menu on mouseenter event.
   * @param e Event
   */
  mouseLeave(event) {
    if (this.navbarService.isFixedMenu()) {
      return;
    }
    const _self = this;
    const navBar = this.document.getElementById('navbar-header');
    const mainMenu = this.document.getElementById('main-menu');
    if (navBar && navBar.classList.contains('expanded')) {
      this.insideTm = setTimeout(() => {
        if (!_self.navbarService.isMouseInRegion()) {
          this._renderer.removeClass(navBar, 'expanded');
          this._renderer.removeClass(mainMenu, 'expanded');
          this.resetOpenMenu();
          this.setOpenInNavbar(false);
        }
      }, 100);
    }
    this.navbarService.setMouseInRegion(false);
  }

  // example to update badge value dynamically from another component
  updateMenuBadgeValue() {
    for (let i = 0; i < this._menuSettingsConfig.items.length; i++) {
      if (this._menuSettingsConfig.items[i].badge) {
        this._menuSettingsConfig.items[i].badge.value = 19;
      }
    }
    this._menuSettingsService.config = this._menuSettingsConfig;
  }

  handleCollapseOfMenu(element) {
    if (element.classList && element.classList.contains('has-sub') && element.classList.contains('open')) {
      element.classList.remove('open');
      element.classList.remove('hover');
      element.classList.add('menu-collapsed-open');
    }
  }

  handleExpandOfMenu(element) {
    if (element.classList && element.classList.contains('has-sub') &&
      element.classList.contains('menu-collapsed-open')) {
      element.classList.remove('menu-collapsed-open');
      element.classList.add('open');
      element.classList.add('hover');
    }
  }

  toggleMenu(event) {
    const target = event.target || event.srcElement || event.currentTarget;
    const parent = target.parentElement;
    if (parent && parent.classList.contains('has-sub')) {
      this.openSubMenuUsingParent(parent);
    } else {
      const parentOfParent = parent.parentElement;
      this.openSubMenuUsingParent(parentOfParent);
    }
  }

  openSubMenuUsingParent(parent) {
    if (parent.classList && parent.classList.contains('has-sub') &&
      !parent.classList.contains('open')) {
      parent.classList.add('open');
    } else if (parent.classList && parent.classList.contains('has-sub') &&
      parent.classList.contains('open')) {
      parent.classList.remove('open');
    }
  }

  toggleFullScreen() {
    const toggleIcon = document.getElementsByClassName('ficon');

    if (toggleIcon.item(0).classList.contains('ft-maximize')) {
      this.openfullscreen();
      // console.log('maximized');
      this._renderer.removeClass(toggleIcon.item(0), 'ft-maximize');
      this._renderer.addClass(toggleIcon.item(0), 'ft-minimize');
    } else if (toggleIcon.item(0).classList.contains('ft-minimize')) {
      this.closefullscreen();
      // console.log('minimized');
      this._renderer.addClass(toggleIcon.item(0), 'ft-maximize');
      this._renderer.removeClass(toggleIcon.item(0), 'ft-minimize');
    }
  }

  openfullscreen() {
    // Trigger fullscreen
    // tslint:disable-next-line: no-shadowed-variable
    const docElmWithBrowsersFullScreenFunctions = document.documentElement as HTMLElement & {
      mozRequestFullScreen(): Promise<void>;
      webkitRequestFullscreen(): Promise<void>;
      msRequestFullscreen(): Promise<void>;
    };

    if (docElmWithBrowsersFullScreenFunctions.requestFullscreen) {
      docElmWithBrowsersFullScreenFunctions.requestFullscreen();
    } else if (docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen) { /* Firefox */
      docElmWithBrowsersFullScreenFunctions.mozRequestFullScreen();
    } else if (docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      docElmWithBrowsersFullScreenFunctions.webkitRequestFullscreen();
    } else if (docElmWithBrowsersFullScreenFunctions.msRequestFullscreen) { /* IE/Edge */
      docElmWithBrowsersFullScreenFunctions.msRequestFullscreen();
    }
  }

  closefullscreen() {
    // tslint:disable-next-line: no-shadowed-variable
    const docWithBrowsersExitFunctions = document as Document & {
      mozCancelFullScreen(): Promise<void>;
      webkitExitFullscreen(): Promise<void>;
      msExitFullscreen(): Promise<void>;
    };
    if (docWithBrowsersExitFunctions.exitFullscreen) {
      docWithBrowsersExitFunctions.exitFullscreen();
    } else if (docWithBrowsersExitFunctions.mozCancelFullScreen) { /* Firefox */
      docWithBrowsersExitFunctions.mozCancelFullScreen();
    } else if (docWithBrowsersExitFunctions.webkitExitFullscreen) { /* Chrome, Safari and Opera */
      docWithBrowsersExitFunctions.webkitExitFullscreen();
    } else if (docWithBrowsersExitFunctions.msExitFullscreen) { /* IE/Edge */
      docWithBrowsersExitFunctions.msExitFullscreen();
    }

  }


  toggleFixMenu(e) {
    if (this.document.body.classList.contains('menu-expanded')) {
      // show the left aside menu
      console.log("menu-expand")
      this.navbarService.setFixedMenu(false);
      this.document.body.classList.remove('menu-expanded');
      this.document.body.classList.add('menu-collapsed');
      // Change switch icon
      this._themeSettingsConfig.menu = 'collapse';
    } else {
      console.log("menu-collapsed")

      this.navbarService.setFixedMenu(true);
      this.document.body.classList.remove('menu-collapsed');
      this.document.body.classList.add('menu-expanded');
      // Change switch icon
      this._themeSettingsConfig.menu = 'expand';
    }
    const navBar = this.document.getElementById('navbar-header');
    const mainMenu = this.document.getElementById('main-menu');
    this._renderer.addClass(navBar, 'expanded');
    this._renderer.addClass(mainMenu, 'expanded');
    setTimeout(() => { AppConstants.fireRefreshEventOnWindow(); }, 300);
  }


  toggleNavigation(e) {
    const sidenav = document.getElementById('sidenav-overlay');
    const sidebarLeft = document.getElementById('sidebar-left') || document.getElementById('email-app-menu') ||
      document.getElementById('sidebar-todo');
    const contentOverlay = document.getElementById('content-overlay');

    if (this.document.body.classList.contains('menu-open') && (this.router.url === '/todos' || this.router.url === '/contacts' ||
      this.router.url === '/email' || this.router.url === '/chats')) {
      this.document.body.classList.remove('menu-open');
      this._renderer.removeClass(sidenav, 'd-block');
      this._renderer.removeClass(contentOverlay, 'show');
      this.document.body.classList.add('menu-close');
      this._renderer.addClass(sidenav, 'd-none');
      this.showNavbar = false;
    } else if (this.document.body.classList.contains('menu-open')) {
      this.document.body.classList.remove('menu-open');
      this._renderer.removeClass(sidenav, 'd-block');
      this.document.body.classList.add('menu-close');
      this._renderer.addClass(sidenav, 'd-none');
      this.showNavbar = false;
    } else {
      this._renderer.removeClass(sidenav, 'd-none');
      this.document.body.classList.remove('menu-close');
      this.document.body.classList.add('menu-open');
      this._renderer.addClass(sidenav, 'd-block');
      this.showNavbar = false;
    }

    if (sidebarLeft) {
      this._renderer.removeClass(sidebarLeft, 'show');
      this._renderer.removeClass(contentOverlay, 'show');
    }
  }

  toggleNavbar(e) {
    if (this.showNavbar) {
      this.showNavbar = false;
    } else {
      this.showNavbar = true;
    }
  }

  public clickSearch() {
    if (this.isHeaderSearchOpen) {
      this.isHeaderSearchOpen = false;
    } else {
      this.isHeaderSearchOpen = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < AppConstants.MOBILE_RESPONSIVE_WIDTH) {
      this.isMobile = true;
      this.showNavbar = false;
    } else {
      this.isMobile = false;
      this.showNavbar = true;
    }
  }

  echoTest() {
    const telemoSdk = new Telemo();
    const agentUUID = this.dataOfUser.agent_domain_uuid;
    // console.log('invite_detail', 9196, '', '', '', agentUUID);
    telemoSdk.audioCall(9196, '', '', '', agentUUID);
    sessionStorage.setItem("caller_number", $("#telNumber").val());
    $("#terminateCall").show();
    $("#makeAudioCall").hide();
    $("#makeAudioCallBtnContainer").hide();
    $("#dailPadConTainer").hide();
    $("#outgoingResetTimer").hide();
    $("#resetTime").hide();
    $("#outgoingResetTimer").hide();
    $("#dailPadConTainer").hide();
  }
}
function myStopFunction() {
  throw new Error('Function not implemented.');
}

function datacheck(datacheck: any) {
  throw new Error('Function not implemented.');
}

function dataRemove() {
  throw new Error('Function not implemented.');
}

